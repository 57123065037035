import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProductShowcaseDto } from 'converters/productShowcase'
import { TRedux } from 'types'
import { EntityStatus } from 'types/redux'
import { Maybe } from 'types/utils'

import { commonReducers } from '../commonReducers'

const productShowcaseProductsAdapter = createEntityAdapter<ProductShowcaseDto.Product>({
	selectId: ({ type }) => type,
})

export const productShowcaseProductsSelector =
	productShowcaseProductsAdapter.getSelectors<TRedux.RootState>(
		(state) => state.productsShowcase.products
	)

const initialState = productShowcaseProductsAdapter.getInitialState({
	status: 'pending' as EntityStatus,
	saving: null as Maybe<ProductShowcaseDto.Saving>,
	savingPrograms: [] as ProductShowcaseDto.SavingProgram[],
	loan: null as Maybe<ProductShowcaseDto.Loan>,
	loading: null as Maybe<ProductShowcaseDto.Product['type']>,
})

const productsSlice = createSlice({
	name: 'productsShowcase/products',
	initialState,

	reducers: {
		setLoan: (state, { payload }: PayloadAction<ProductShowcaseDto.Loan>) => {
			state.loan = payload
		},

		setSaving: (state, { payload }: PayloadAction<ProductShowcaseDto.Saving>) => {
			state.saving = payload
		},

		setSavingPrograms: (state, { payload }: PayloadAction<ProductShowcaseDto.SavingProgram[]>) => {
			state.savingPrograms = payload
		},

		setLoading: (state, { payload }: PayloadAction<(typeof initialState)['loading']>) => {
			state.loading = payload
		},

		setData: productShowcaseProductsAdapter.setAll,
		setStatus: commonReducers.setStatus,
		reset: commonReducers.reset(initialState),
	},
})

const { actions: productShowcaseProductsReducerActions, reducer } = productsSlice

export { productShowcaseProductsReducerActions, reducer as productsReducer }
