import { Suspense } from 'react'
import { createBrowserRouter,RouteObject } from 'react-router-dom'
import { Route } from 'client/Route'
import { BankAccount } from 'components/pages/BankAccount'
import { Chat } from 'components/pages/Chat'
import { Document } from 'components/pages/Document'
import { Loan } from 'components/pages/Loan'
import { Main } from 'components/pages/Main'
import { NewLoan } from 'components/pages/NewLoan'
import { NewSaving } from 'components/pages/NewSaving'
import { Onboarding } from 'components/pages/Onboarding'
import { ProductsShowcase } from 'components/pages/ProductsShowcase'
import { Profile } from 'components/pages/Profile'
import { Saving } from 'components/pages/Saving'
import { SavingSteps } from 'components/pages/SavingSteps'

import { PageLoading } from 'components/common'

import { urls } from './urls'
import { ErrorLayout } from 'components/Layouts'

const routeObjects: RouteObject[] = [
	{
		element: <Route />,
		errorElement: <ErrorLayout />,
		children: [
			{
				path: urls.profile.path,
				element: (
					<Suspense fallback={<PageLoading />}>
						<Profile />
					</Suspense>
				),
				handle: {
					title: urls.profile.title,
				},
			},
			{
				path: urls.onboarding.path,
				element: (
					<Suspense fallback={<PageLoading />}>
						<Onboarding />
					</Suspense>
				),
				handle: {
					title: urls.onboarding.title,
					layout: {
						type: 'plain',
						props: {
							specialMobile: true,
						},
					},
				},
			},
			{
				path: urls.bankAccount.path(),
				element: (
					<Suspense fallback={<PageLoading />}>
						<BankAccount />
					</Suspense>
				),
				handle: {
					layout: {
						type: 'main',
						props: {
							specialMobile: true,
						},
					},
					title: urls.bankAccount.title,
				},
			},
			{
				path: urls.chat.path,
				element: (
					<Suspense fallback={<PageLoading />}>
						<Chat />
					</Suspense>
				),
				handle: {
					title: urls.chat.title,
				},
			},
			{
				path: urls.newLoan.path,
				element: (
					<Suspense fallback={<PageLoading />}>
						<NewLoan />
					</Suspense>
				),
				handle: {
					title: urls.newLoan.title,
				},
			},
			{
				path: urls.savingSteps.path(),
				element: (
					<Suspense fallback={<PageLoading />}>
						<SavingSteps />
					</Suspense>
				),
				handle: {
					title: urls.savingSteps.title,
				},
			},
			{
				path: urls.newSaving.path,
				element: (
					<Suspense fallback={<PageLoading />}>
						<NewSaving />
					</Suspense>
				),
				handle: {
					title: urls.newSaving.title,
				},
			},
			{
				path: urls.saving.path(),
				element: (
					<Suspense fallback={<PageLoading />}>
						<Saving />
					</Suspense>
				),
				handle: {
					title: urls.saving.title,
					layout: {
						type: 'main',
						props: {
							specialMobile: true,
						},
					},
				},
			},
			{
				path: urls.loan.path(),
				element: (
					<Suspense fallback={<PageLoading />}>
						<Loan />
					</Suspense>
				),
				handle: {
					title: urls.loan.title,
					layout: {
						type: 'main',
						props: {
							specialMobile: true,
						},
					},
				},
			},
			{
				path: urls.productsShowcase.path,
				element: (
					<Suspense fallback={<PageLoading />}>
						<ProductsShowcase />
					</Suspense>
				),
				handle: {
					title: urls.productsShowcase.title,
				},
			},
			{
				path: urls.docs.path,
				element: (
					<Suspense fallback={<PageLoading />}>
						<Document />
					</Suspense>
				),
				handle: {
					title: urls.docs.title,
				},
			},
			{
				path: urls.main.path,
				element: (
					<Suspense fallback={<PageLoading />}>
						<Main />
					</Suspense>
				),
				handle: {
					title: urls.main.title,
				},
			},
		],
	},
]

export const router = createBrowserRouter(routeObjects)
