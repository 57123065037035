import { linkedEntity } from './linkedEntity'
import { main } from './main'
import { selfieIdentification } from './selfieIdentification'
import { signingCertificate } from './signingCertificate'

export const profile = {
	main,
	linkedEntity,
	signingCertificate,
	selfieIdentification,
}

export namespace Profile {
	export type Main = ReturnType<typeof main>
	export type LinkedEntity = ReturnType<typeof linkedEntity>
	export type SigningCertificate = ReturnType<typeof signingCertificate>
	export type SelfieIdentification = ReturnType<typeof selfieIdentification>
}
