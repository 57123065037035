import { DefaultValues } from 'helpers/defaultsUtils'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		title: model.string(['title']),
		personalDataAgreementUrl: model.string(['personalDataAgreementUrl']),
		requiredDocuments: model.array(['requiredDocuments']).map((_, i) => ({
			id: model.string(['requiredDocuments', i, 'id']),
			title: model.string(['requiredDocuments', i, 'title']),
		})),
		status: model.const(data.status, {
			IDENTIFIED: 'IDENTIFIED',
			NOT_IDENTIFIED: 'NOT_IDENTIFIED',
			ON_VERIFICATION: 'ON_VERIFICATION',
		} as const),
	}
}
