import { DefaultValues } from 'helpers/defaultsUtils'

export const brief = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		title: model.string(['title']),
		description: model.string(['description']),
		iconUrl: model.string(['iconUrl']),
		descriptionUrl: model.string(['descriptionUrl']),
	}
}
