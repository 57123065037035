import Keycloak from 'keycloak-js'

/**
 * Keycloak instance
 */
const keycloak = new Keycloak({
	url: window.__env__.AUTH_SERVICE_URL,
	clientId: window.__env__.KEYCLOAK_CLIENT_ID,
	realm: window.__env__.KEYCLOAK_REALM,
})

export { keycloak }
