import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BankAccountDto } from 'converters/bankAccount'
import { TRedux } from 'types'
import { EntityStatus } from 'types/redux'

import { commonReducers } from '../commonReducers'

const bankAccountListAdapter = createEntityAdapter<BankAccountDto.List>()

export const bankAccountListSelector = bankAccountListAdapter.getSelectors<TRedux.RootState>(
	(state) => state.bankAccount.list
)

const initialState = bankAccountListAdapter.getInitialState({
	status: 'pending' as EntityStatus,
	isInitialized: false,
})

const listSlice = createSlice({
	name: 'bankAccount/list',
	initialState,

	reducers: {
		setData: bankAccountListAdapter.setAll,

		setInitialized: (state, action: PayloadAction<boolean>) => {
			state.isInitialized = action.payload
		},

		setStatus: commonReducers.setStatus,
		reset: commonReducers.reset(initialState),
	},
})

const { actions: bankAccountListReducerActions, reducer } = listSlice

export { bankAccountListReducerActions, reducer as listReducer }
