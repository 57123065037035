import { DefaultValues } from 'helpers/defaultsUtils'

export const selfieIdentification = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		status: model.const(data.status, {
			IDENTIFIED: 'IDENTIFIED',
			NOT_IDENTIFIED: 'NOT_IDENTIFIED',
		} as const),
	}
}

