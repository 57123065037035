import * as React from 'react'
import clsx from 'clsx'
import { BankAccountDto } from 'converters/bankAccount'

import { moneyUtils } from '@creditclubteam/helpers'
import { Image, Text } from 'components/common'

import styles from './Item.module.scss'

const getPrefix = (direction: BankAccountDto.MoneyMovement['direction']) =>
	((
		{
			INCOME: '+',
			OUTCOME: '-',
		} as Record<Partial<typeof direction>, string>
	)[direction])

export const Item = React.memo(
	({
		direction,
		title,
		iconUrl,
		amount,
	}: Pick<BankAccountDto.MoneyMovement, 'direction' | 'title' | 'iconUrl' | 'amount'>) => {
		return (
			<div className={styles.item}>
				<div className={styles.info}>
					<Image src={iconUrl} />
					<Text>{title}</Text>
				</div>
				<Text className={clsx({ [styles.green]: direction === 'INCOME' })}>
					{getPrefix(direction)}
					{moneyUtils.RUB(amount)}
				</Text>
			</div>
		)
	}
)
