import { contacts } from './contacts'
import { manager } from './manager'

export const support = {
	contacts,
	manager,
}

export namespace SupportDto {
	export type Contacts = ReturnType<typeof contacts>
	export type Manager = ReturnType<typeof manager>
}
