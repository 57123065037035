import { loan } from './loan'
import { product } from './product'
import { saving } from './saving'
import { savingProgram } from './savingProgram'

export const productShowcase = {
	product,
	saving,
	savingProgram,
	loan,
}

export namespace ProductShowcaseDto {
	export type Product = ReturnType<typeof product>
	export type Saving = ReturnType<typeof saving>
	export type SavingProgram = ReturnType<typeof savingProgram>
	export type Loan = ReturnType<typeof loan>
}
