import * as React from 'react'
import ReactModal from 'react-modal'
import cx from 'clsx'
import Сross from 'images/cross.svg'

import { Image } from '../Image'
import { Text } from '../Text'

import styles from './Modal.module.scss'

export interface ModalProps {
	isOpen: boolean
	title?: string
	showCloseButton?: boolean
	subText?: React.ReactNode
	className?: string
	onAfterClose?: () => void
	overlayClassName?: string
	headerClassName?: string
	closeTimeoutMS?: number
	shouldCloseOnOverlayClick?: boolean
	children?: React.ReactNode

	onClose: () => void
}

const Modal: React.FC<ModalProps> = (props) => {
	const {
		isOpen,
		onClose,
		className,
		onAfterClose,
		overlayClassName,
		shouldCloseOnOverlayClick,
		children,
		title,
		showCloseButton,
		headerClassName,
		subText,
	} = props

	const modalProps: React.ComponentProps<typeof ReactModal> = {
		isOpen,
		ariaHideApp: false,
		onRequestClose: onClose,
		onAfterClose,
		shouldCloseOnOverlayClick,
		className: cx(styles.modal, className),
		overlayClassName: cx(styles.modalOverlay, overlayClassName),
		closeTimeoutMS: 500,
	}

	return (
		<ReactModal {...modalProps}>
			{showCloseButton && (
				<span className={styles.cross} onClick={onClose}>
					<Image src={Сross} alt='закрыть' />
				</span>
			)}
			{(title || subText) && (
				<div className={cx(styles.header, headerClassName)}>
					{title && (
						<Text type='h2' className={styles.title}>
							{title}
						</Text>
					)}
					{subText}
				</div>
			)}
			{children}
		</ReactModal>
	)
}

Modal.defaultProps = {
	shouldCloseOnOverlayClick: true,
	closeTimeoutMS: 500,
	showCloseButton: true,
}

export { Modal }
