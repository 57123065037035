import * as React from 'react'
import clsx from 'clsx'
import { TMedia } from 'types'

import styles from './Button.module.scss'

export type ButtonProps = {
	theme?: 'blue' | 'green' | 'error' | 'grey' | 'light'
	fullWidth?: boolean
	indent?: TMedia.Indents
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

const Button: React.FC<ButtonProps> = ({ theme, fullWidth, indent, className, ...attributes }) => {
	return (
		<button
			className={clsx(
				styles.wrap,
				{
					[styles.xs]: indent === 'xs',
					[styles.s]: indent === 's',
					[styles.m]: indent === 'm',
					[styles.l]: indent === 'l',
					[styles.xl]: indent === 'xl',
				},
				{
					[styles.full]: fullWidth,
					[styles.grey]: theme === 'grey',
					[styles.green]: theme === 'green',
					[styles.error]: theme === 'error',
					[styles.blue]: theme === 'blue',
					[styles.light]: theme === 'light',
					[className!]: !!className,
				}
			)}
			onClick={attributes.disabled ? undefined : attributes.onClick}
			{...attributes}
		>
			{attributes.children}
		</button>
	)
}

Button.defaultProps = {
	type: 'submit',
	theme: 'blue',
	fullWidth: true,
}

export { Button }
