import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DocumentDto } from 'converters/document'
import { commonReducers } from 'reducers/commonReducers'

const initialState: DocumentDto.Pagination = {
	empty: null,
	first: null,
	last: null,
	number: null,
	size: null,
	totalElements: null,
	totalPages: null,
}

const paginationSlice = createSlice({
	name: 'document/pagination',
	initialState,

	reducers: {
		setData: (state, action: PayloadAction<DocumentDto.Pagination>) => {
			return {
				...state,
				...action.payload,
			}
		},

		reset: commonReducers.reset(initialState),
	},
})

const { actions, reducer } = paginationSlice

export { actions as paginationReducerActions, reducer as paginationReducer }
