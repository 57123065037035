import { DefaultValues } from 'helpers/defaultsUtils'

import { linkedEntity } from './linkedEntity'
import { signingCertificate } from './signingCertificate'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		email: model.string(['email']),
		snils: model.string(['snils']),
		phone: model.string(['phone']),
		taxId: model.string(['taxId']),
		name: model.string(['name']),
		surname: model.string(['surname']),
		patronymic: model.string(['patronymic']),
		passport: {
			series: model.string(['passport', 'series']),
			number: model.string(['passport', 'number']),
		},
		bankRequisites: data?.bankRequisites
			? {
					bankName: model.string(['bankRequisites', 'bankName']),
					accountNumber: model.string(['bankRequisites', 'accountNumber']),
			  }
			: null,
		signingCertificates: model.array(['signingCertificates']).map(signingCertificate),
		linkedEntities: model.array(['linkedEntities']).map(linkedEntity),
	}
}
