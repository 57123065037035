import { PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'

import styles from './NavItem.module.scss'

export interface NavItemProps extends PropsWithChildren<unknown> {
	to?: string
	className?: string
	onClick?: (event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

export const NavItem = (props: NavItemProps) => {
	return (
		<li
			className={clsx(styles.wrap, {
				[props.className!]: !!props.className,
			})}
		>
			{props.to ? (
				<NavLink
					className={({ isActive }) => clsx(styles.link, isActive && styles.active)}
					to={props.to}
					onClick={props.onClick}
				>
					{props.children}
				</NavLink>
			) : (
				<a className={styles.link} onClick={props.onClick}>
					{props.children}
				</a>
			)}
		</li>
	)
}
