import { DefaultValues } from 'helpers/defaultsUtils'

export const pagination = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		totalPages: model.null<number>(['totalPages']),
		totalElements: model.null<number>(['totalElements']),
		number: model.null<number>(['number']),
		size: model.null<number>(['size']),
		first: model.null<boolean>(['first']),
		last: model.null<boolean>(['last']),
		empty: model.null<boolean>(['empty']),
	}
}
