import * as React from 'react'
import { MEDIA_BREAKPOINTS } from 'const/mediaBreakpoints'

import { utils } from 'helpers'

const useMedia = () => {
	const [media, setMedia] = React.useState<keyof typeof MEDIA_BREAKPOINTS>(
		utils.getMedia(window.innerWidth)
	)

	const handleResize = React.useCallback(() => {
		const newMedia = utils.getMedia(window.innerWidth)

		if (newMedia !== media) {
			setMedia(newMedia)
		}
	}, [media])

	React.useEffect(() => {
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [media, handleResize])

	return media
}

export { useMedia }
