import { main } from './main'
import { step } from './step'

export const onboarding = {
	main,
	step,
}

export namespace Onboarding {
	export type Main = ReturnType<typeof main>
	export type Step = ReturnType<typeof step>
}
