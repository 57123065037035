import * as React from 'react'
import { ToastContainer } from 'react-toastify'
import { useMedia } from 'hooks'

export const NoticeContainer = () => {
	const media = useMedia()

	const props: React.ComponentProps<typeof ToastContainer> = {
		position: media === 'DESKTOP' ? 'bottom-left' : 'top-left',
		hideProgressBar: true,
		newestOnTop: media === 'DESKTOP',
		closeOnClick: true,
		limit: media === 'DESKTOP' ? 3 : 1,
	}

	return <ToastContainer {...props} />
}
