import { Container, Text } from 'components/common'

import { PlainLayout } from '../PlainLayout'

import styles from './ErrorLayout.module.scss'

export const ErrorLayout = () => {
	return (
		<PlainLayout error>
			<Container className={styles.container}>
				<Text className={styles.title} type='h1'>
					Ошибка приложения
				</Text>
				<Text type='body'>Мы уже знаем об ошибке и работаем над её исправлением.</Text>
				<Text type='body' className={styles.action}>
					Попробуйте{' '}
					<span className={styles.btn} onClick={() => window.location.reload()}>
						обновить страницу
					</span>
				</Text>
			</Container>
		</PlainLayout>
	)
}
