import { DefaultValues } from 'helpers/defaultsUtils'

import { step } from './step'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		steps: model.array(['steps']).map(step),
	}
}
