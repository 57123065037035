import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Outlet,
	RouteObject,
	UIMatch,
	useLocation,
	useMatches,
	useNavigate,
} from 'react-router-dom'
import { layoutAction } from 'actions/layout'
import { MainLayout, PlainLayout } from 'components/Layouts'
import { selectShouldOnboardingShow } from 'reducers/onboarding/main'
import { selectProfile } from 'reducers/profile'
import { urls } from 'routes/urls'
import { TRedux } from 'types'

import { PageLoading } from 'components/common'
import { FadeIn } from 'components/common/FadeIn'

export const Route = () => {
	const dispatch = useDispatch<TRedux.TDispatch>()
	const navigate = useNavigate()
	const location = useLocation()
	const matches = useMatches() as UIMatch<unknown, RouteObject['handle']>[]
	const { handle: { title = 'Credit.Club', layout } = {} } = (matches.find(
		({ handle }) => handle
	) ?? {}) as RouteObject
	const shouldShowOnboarding = useSelector(selectShouldOnboardingShow)
	const profile = useSelector(selectProfile)

	useEffect(() => {
		if ('scrollTo' in window) {
			window.scrollTo(0, 0)
		}
	}, [location.pathname])

	useEffect(() => {
		dispatch(layoutAction())
	}, [dispatch])

	useEffect(() => {
		if (shouldShowOnboarding && window.location.pathname !== urls.onboarding.path)
			navigate(urls.onboarding.path)
	}, [shouldShowOnboarding, navigate])

	if (layout?.type === 'plain') {
		return (
			<FadeIn>
				<PlainLayout title={title} props={layout.props}>
					{!profile ? <PageLoading /> : <Outlet />}
				</PlainLayout>
			</FadeIn>
		)
	}

	return (
		<FadeIn>
			<MainLayout title={title} props={layout?.props} backgroundTheme={layout?.backgroundTheme}>
				{!profile ? <PageLoading /> : <Outlet />}
			</MainLayout>
		</FadeIn>
	)
}
