import * as React from 'react'
import clsx from 'clsx'

import { FadeIn } from 'components/common/FadeIn'

import styles from './Container.module.scss'

export type ContainerProps = {
	children?: React.ReactNode
	fadeIn?: boolean
	className?: string
}

const Container = (props: ContainerProps) => {
	return (
		<div
			className={clsx(styles.wrap, {
				[props.className!]: !!props.className,
			})}
		>
			{props.fadeIn ? <FadeIn>{props.children}</FadeIn> : props.children}
		</div>
	)
}

export { Container }
