/* eslint-disable simple-import-sort/imports */
import 'scss/global-styles.scss'

import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { KeycloakProvider } from './keycloak'
import { ErrorBoundary, init as sentryInit } from '@sentry/react'
import axios from 'axios'
import { ErrorLayout } from 'components/Layouts/ErrorLayout'
import { NoticeContainer } from 'components/NoticeContainer'
import { MetrikaInitializer } from 'features/metrika'
import { router } from 'routes/list'
import { store } from 'reducers'

import { ConfigureAxios } from '@creditclubteam/kit/legacy-helpers'
import { keycloak } from './keycloak/instance'

import { sentryConfig } from './sentry'
import { registerSW } from 'virtual:pwa-register'

import 'react-toastify/dist/ReactToastify.css'

new ConfigureAxios({
	env: window.__env__.BUILD_MODE ?? 'localhost',
	baseURL: {
		localhost: window.__env__.BASE_URL,
		development: window.__env__.BASE_URL,
		production: window.__env__.BASE_URL,
	},
	updateTokenFn: () => null,
	userReceivingTokenAfterFail: () => null,
}).init({
	axios,
	keycloakInstance: keycloak,
})
sentryInit(sentryConfig)

createRoot(document.getElementById('root')!).render(
	<StrictMode>
		<HelmetProvider>
			<ErrorBoundary fallback={<ErrorLayout />}>
				<Provider store={store}>
					<KeycloakProvider>
						<MetrikaInitializer />
						<NoticeContainer />
						<RouterProvider router={router} />
					</KeycloakProvider>
				</Provider>
			</ErrorBoundary>
		</HelmetProvider>
	</StrictMode>
)

if (window.__env__.BUILD_MODE !== 'localhost') {
	registerSW({ immediate: true })
}