import { DefaultValues } from 'helpers/defaultsUtils'

export const manager = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		fullName: model.string(['fullName']),
		avatar: model.string(['avatar']),
		extensionNumber: model.string(['extensionNumber']),
	}
}
