import { DefaultValues } from 'helpers/defaultsUtils'

export const document = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		createdAt: model.string(['createdAt']),
		title: model.string(['title']),
		extension: model.string(['extension']),
		url: model.string(['url']),
		readonly: model.null<boolean>(['readonly']),
		size: {
			bytes: model.null<number>(['size', 'bytes']),
			title: model.string(['size', 'title']),
		},
	}
}
