import { instruction } from './instruction'
import { list } from './list'
import { moneyMovement } from './moneyMovement'
import { paymentSchedule } from './paymentSchedule'
import { single } from './single'

export const saving = {
	list,
	single,
	moneyMovement,
	paymentSchedule,
	instruction,
}

export namespace SavingDto {
	export type List = ReturnType<typeof list>
	export type Single = ReturnType<typeof single>
	export type PaymentSchedule = ReturnType<typeof paymentSchedule>
	export type Instruction = ReturnType<typeof instruction>
	export type MoneyMovement = ReturnType<typeof moneyMovement>
}
