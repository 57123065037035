import axios, { CancelToken, CancelTokenSource } from 'axios'
import { store } from 'reducers'
import { TUtils } from 'types'
import { TRedux } from 'types'

type Request<T extends (...args: any) => any> = TUtils.Maybe<
	(...args: Parameters<T>) => (cancelToken: CancelToken, dispatch: TRedux.TDispatch) => Promise<any>
>

export class PollingRequest {
	private request: any
	private interval: number
	private intervalTime: number
	private cancelToken: CancelTokenSource
	private isPollingVal: boolean

	constructor() {
		this.request = null
		this.intervalTime = 15000
		this.interval = 0
		this.isPollingVal = false
		this.cancelToken = axios.CancelToken.source()
	}

	private check() {
		if (typeof this.request !== 'function') {
			return false
		}

		return true
	}

	private createAndSetCancelToken() {
		this.cancelToken = axios.CancelToken.source()
	}

	private sendRequest<T>(...args: T[]) {
		return this.request!(...args)(this.cancelToken.token, store.dispatch)
	}

	isPolling() {
		return this.isPollingVal
	}

	setRequest<T extends (...args: any[]) => any>(request: NonNullable<Request<T>>) {
		if (this.isPollingVal) {
			this.stopPolling()
		}

		this.request = request
		return this
	}

	setIntervalTime(intervalTime: PollingRequest['intervalTime']) {
		this.intervalTime = intervalTime
		return this
	}

	startPolling<T = any>({
		immidiateStart = true,
		forceStart = false,
		args = [],
	}: { immidiateStart?: boolean; forceStart?: boolean; args?: T[] } | undefined = {}) {
		if (forceStart) {
			this.stopPolling()
			this.startPolling({ immidiateStart, forceStart: false, args })
		} else if (this.check() && !this.isPolling()) {
			this.isPollingVal = true

			if (immidiateStart) this.sendRequest(...args)

			this.interval = window.setInterval(() => {
				this.sendRequest(...args)
			}, this.intervalTime)
		}

		return this
	}

	stopPolling() {
		this.isPollingVal = false

		this.cancelToken.cancel()
		clearInterval(this.interval)
		this.createAndSetCancelToken()

		return this
	}
}
