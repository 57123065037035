import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import clsx from 'clsx'
import { useMedia } from 'hooks'
import { TUtils } from 'types'

import { Footer } from '../Footer'

import { Header } from './Header'

import styles from '../Layouts.module.scss'

export interface PlainLayoutProps extends React.PropsWithChildren<unknown> {
	children?: React.ReactNode
	title?: string
	error?: boolean
	props?: {
		specialMobile?: boolean
	}
}

export const PlainLayout = ({ props, children, title }: PlainLayoutProps) => {
	const wrap = React.useRef<TUtils.Maybe<HTMLDivElement>>(null)
	const media = useMedia()

	const handlerInnerHeight = () => {
		if (wrap.current) {
			wrap.current.style.minHeight = `${window.innerHeight}px`
		}
	}

	const defaultMode = media === 'DESKTOP' || !props?.specialMobile

	React.useEffect(() => {
		handlerInnerHeight()

		window.addEventListener('resize', handlerInnerHeight)

		return () => {
			window.removeEventListener('resize', handlerInnerHeight)
		}
	})

	return (
		<>
			{title && (
				<Helmet title={title}>
					<meta property='og:title' content={title} />
				</Helmet>
			)}
			<div ref={wrap} className={styles.wrap}>
				{defaultMode && <Header plain />}
				<main className={clsx(styles.main)}>{children}</main>
				{defaultMode && <Footer plain />}
			</div>
		</>
	)
}
