import { DefaultValues } from 'helpers/defaultsUtils'

export const pledge = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		title: model.string(['title']),
		address: {
			mergedAddress: model.string(['address', 'mergedAddress']),
			location: {
				latitude: model.null<number>(['address', 'location', 'latitude']),
				longitude: model.null<number>(['address', 'location', 'longitude']),
			},
		},
		photos: model.array(['photos']).map((_, i) => ({
			id: model.string(['photos', i, 'id']),
			url: model.string(['photos', i, 'url']),
		})),
	}
}
