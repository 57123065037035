import { accountStats } from './accountStats'
import { main } from './main'

export const bonusAccount = {
	main,
	accountStats,
}

export namespace BonusAccountDto {
	export type Main = ReturnType<typeof main>
	export type AccountStats = ReturnType<typeof accountStats>
}
