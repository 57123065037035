import { DefaultValues } from 'helpers/defaultsUtils'

export const product = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		name: model.string(['name']),
		secondaryText: model.string(['secondaryText']),
		type: model.const(data.type, {
			LOAN: 'LOAN',
			SAVING: 'SAVING',
		} as const),
	}
}
