import * as React from 'react'
import type { TabProps as ReactTabProps } from 'react-tabs'
import { Tab as ReactTab } from 'react-tabs'
import clsx from 'clsx'

import styles from './Tab.module.scss'

export type TabProps = ReactTabProps & { className?: string }

const Tab: React.FC<TabProps> & { tabsRole?: string } = ({
	children,
	className,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	ref,

	...restOfProps
}) => {
	return (
		<ReactTab
			{...restOfProps}
			className={clsx(styles.wrap, {
				[className!]: !!className,
			})}
			selectedClassName={clsx(styles.selected, {
				[restOfProps.selectedClassName!]: !!restOfProps.selectedClassName,
			})}
		>
			{children}
		</ReactTab>
	)
}

Tab.tabsRole = 'Tab'

export { Tab }
