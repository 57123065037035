import { normalize, schema } from 'normalizr'
import { head, last, sort } from 'ramda'

import { date } from '@creditclubteam/helpers'
import { DefaultValues } from 'helpers/defaultsUtils'

import { additionalAccrual } from './additionalAccrual'
import { document } from './document'
import { moneyMovement } from './moneyMovement'
import { participant } from './participant'
import { pledge } from './pledge'
import { upcomingPaymentBlock } from './upcomingPaymentBlock'

export const single = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	const [start, end] = [
		head(model.array(['moneyMovements'])),
		last(model.array(['moneyMovements'])),
	].map((v) => v && moneyMovement(v))

	const preservedSortType: (a: string, b: string) => number =
		start && end
			? new Date(end.date).valueOf() - new Date(start.date).valueOf() > 0
				? (a, b) => new Date(a).valueOf() + new Date(b).valueOf()
				: (a, b) => new Date(b).valueOf() - new Date(a).valueOf()
			: () => 0

	const {
		entities: { moneyMovements = [] },
	} = normalize(model.array(['moneyMovements']), [
		new schema.Entity('moneyMovements', undefined, {
			idAttribute: ({ date: value }) => date.format(new Date(value), { to: 'yyyy-MM-dd' }),
			mergeStrategy: (a, b) => [].concat(a, b).sort(preservedSortType),
			processStrategy: (value) => [moneyMovement(value)],
		}),
	])

	return {
		id: model.string(['id']),
		createdAt: model.string(['createdAt']),
		number: model.string(['number']).replace(/\D/gi, ''),
		loanAmount: model.null<number>(['loanAmount']),
		loanDurationMonths: model.null<number>(['loanDurationMonths']),
		annualInterestRate: model.null<number>(['annualInterestRate']),
		consentsRequired: model.null<boolean>(['consentsRequired']),
		upcomingPaymentBlock: data.upcomingPaymentBlock?.upcomingPayment
			? upcomingPaymentBlock(data.upcomingPaymentBlock)
			: null,
		notes: model.array(['notes']).map((_, i) => ({
			title: model.string(['notes', i, 'title']),
			content: model.string(['notes', i, 'content']),
		})),
		overdue: data.overdue
			? {
					overdueBalance: {
						amount: model.null<number>(['overdue', 'overdueBalance', 'amount']),
					},
					overdueDays: model.null<number>(['overdue', 'overdueDays']),
			  }
			: null,
		paymentType: data.paymentType
			? {
					id: model.string(['paymentType', 'id']),
					title: model.string(['paymentType', 'title']),
			  }
			: null,
		debtBalance: data.debtBalance
			? {
					amount: model.null<number>(['debtBalance', 'amount']),
					mainDebt: model.null<number>(['debtBalance', 'mainDebt']),
					interest: model.null<number>(['debtBalance', 'interest']),
			  }
			: null,
		status: {
			id: model.string(['status', 'id']),
			title: model.string(['status', 'title']),
		},
		type: model.const(data.type, {
			APPLICATION: 'APPLICATION',
			LEAD: 'LEAD',
			LOAN: 'LOAN',
		} as const),
		documents: model.array(['documents']).map(document),
		participants: model.array(['participants']).map(participant),
		additionalAccruals: model.array(['additionalAccruals']).map(additionalAccrual),
		pledges: model.array(['pledges']).map(pledge),
		moneyMovements: sort(([a], [b]) => preservedSortType(a, b), Object.entries(moneyMovements)),
	}
}
