import { useEffect } from 'react'
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom'
import {
	BrowserOptions,
	reactRouterV6BrowserTracingIntegration,
	replayIntegration,
} from '@sentry/react'

import { sentryUtils } from '@creditclubteam/helpers'

export const sentryConfig: BrowserOptions = {
	enabled: window.__env__.BUILD_MODE !== 'localhost',
	environment: window.__env__.BUILD_MODE,
	debug: window.__env__.BUILD_MODE === 'development',
	dsn: 'https://7ee5bb64afb5400085e51b7ad6c254e3@o272008.ingest.sentry.io/1484417',
	ignoreErrors: ['Non-Error exception captured', 'Request aborted'],

	integrations: [
		reactRouterV6BrowserTracingIntegration({
			useEffect: useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
		replayIntegration(),
	],

	beforeBreadcrumb(breadcrumb) {
		if (sentryUtils.shouldIgnoreBreadcrumb(breadcrumb)) {
			return null
		}

		return breadcrumb
	},

	beforeSend(event, hint) {
		if (
			sentryUtils.shouldSendError(hint?.originalException, {
				ignoreApiErrors: ['invalid_verification_code'],
			}) ||
			event.tags?.action === 'uploading'
		) {
			return event
		}

		return null
	},
}
