import { urls } from 'routes/urls'
import { TAction } from 'types/redux'

import { documentActionsPolling } from './document'
import { profileActions } from './profile'

export const layoutAction =
	(): TAction<void> =>
	(dispatch, getState): void => {
		if (!getState().profile.data) {
			dispatch(profileActions.get())
		}

		// Запускаем поллинг проверки наличия документов на подпись
		// если не находимся на странице документов и документов нет.
		// В ином случае останавлием поллинг.
		if (
			!documentActionsPolling.signingPackages.isPolling() &&
			!window.location.pathname.includes(urls.docs.path) &&
			!getState().document.signing.ids.length
		) {
			documentActionsPolling.signingPackages.startPolling()
		} else {
			documentActionsPolling.signingPackages.stopPolling()
		}
	}
