import clsx from 'clsx'
import { COMPANY_INFO } from 'const/companyInfo'

import { Container, Row } from 'components/common/Grid'

import styles from './Footer.module.scss'

export type FooterProps = {
	plain?: boolean
	className?: string
}

export const Footer = (props: FooterProps) => {
	return (
		<footer className={clsx(styles.wrap, props.className)}>
			<Container
				className={clsx({
					[styles.container]: props.plain,
				})}
			>
				<Row
					className={clsx(styles.row, {
						[styles.plainRow]: props.plain,
					})}
				>
					<div
						className={clsx(styles.gutter, {
							[styles.plainGutter]: props.plain,
						})}
					>
						{COMPANY_INFO.COPYRIGHT}
					</div>
					<a
						className={clsx(styles.phone, styles.gutter, {
							[styles.plainGutter]: props.plain,
						})}
						href={`tel: ${COMPANY_INFO.CONTACT_PHONE}`}
					>
						{COMPANY_INFO.CONTACT_PHONE}
					</a>
				</Row>
			</Container>
		</footer>
	)
}
