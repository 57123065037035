import * as React from 'react'
import clxs from 'clsx'
import { TMedia } from 'types'

import styles from './Text.module.scss'

export type TextProps = React.PropsWithChildren<{
	type?: 'h1' | 'h2' | 'h3' | 'h4' | 'featured' | 'body' | 'small' | 'button'
	theme?: 'link' | 'secondary' | 'error'
	weight?: TMedia.Weights
	indent?: TMedia.Indents
	tag?: React.ElementType
	className?: string
	onClick?: React.AllHTMLAttributes<any>['onClick']
	tagProps?: React.AllHTMLAttributes<any>
}>

const hElements = Array(4)
	.fill(undefined)
	.map((_, i) => `h${i + 1}`)

export const Text = (props: TextProps) => {
	const {
		type = 'body',
		children,
		weight,
		className,
		tag = 'p',
		onClick,
		indent,
		theme,
		tagProps,
	} = props

	const themeClassnames = {
		[styles['--default']]: !theme,
		[styles['--link']]: theme === 'link',
		[styles['--secondary']]: theme === 'secondary',
		[styles['--error']]: theme === 'error',
	}

	const weightClassNames = {
		[styles['--weight-light']]: weight === 'light',
		[styles['--weight-bold']]: weight === 'bold',
	}

	const indentClassname = {
		[styles.xs]: indent === 'xs',
		[styles.s]: indent === 's',
		[styles.m]: indent === 'm',
		[styles.l]: indent === 'l',
		[styles.xl]: indent === 'xl',
	}

	if (hElements.includes(type)) {
		return React.createElement(
			type,
			{
				...tagProps,
				onClick,
				className: clxs(themeClassnames, weightClassNames, indentClassname, className),
			},
			children
		)
	}

	return React.createElement(
		tag,
		{
			...tagProps,
			onClick,
			className: clxs(
				{
					[styles.featured]: type === 'featured',
					[styles.body]: type === 'body',
					[styles.small]: type === 'small',
					[styles.button]: type === 'button',
					[styles.pointer]: !!onClick,
				},
				themeClassnames,
				weightClassNames,
				indentClassname,
				className
			),
		},
		children
	)
}
