import { list } from './list'
import { moneyMovement } from './moneyMovement'
import { single } from './single'

export const bankAccount = {
	list,
	single,
	moneyMovement,
}

export namespace BankAccountDto {
	export type List = ReturnType<typeof list>
	export type Single = ReturnType<typeof single>
	export type MoneyMovement = ReturnType<typeof moneyMovement>
}
