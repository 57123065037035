import { normalize, schema } from 'normalizr'
import { head, last, sort } from 'ramda'

import { date } from '@creditclubteam/helpers'
import { DefaultValues } from 'helpers/defaultsUtils'

import { moneyMovement } from './moneyMovement'

export const single = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	const [start, end] = [
		head(model.array(['moneyMovements'])),
		last(model.array(['moneyMovements'])),
	].map((v) => v && moneyMovement(v))

	const preservedSortType: (a: string, b: string) => number =
		start && end
			? new Date(end.date).valueOf() - new Date(start.date).valueOf() > 0
				? (a, b) => new Date(a).valueOf() + new Date(b).valueOf()
				: (a, b) => new Date(b).valueOf() - new Date(a).valueOf()
			: () => 0

	const {
		entities: { moneyMovements = [] },
	} = normalize(model.array(['moneyMovements']), [
		new schema.Entity('moneyMovements', undefined, {
			idAttribute: ({ date: value }) => date.format(new Date(value), { to: 'yyyy-MM-dd' }),
			mergeStrategy: (a, b) => [].concat(a, b).sort(preservedSortType),
			processStrategy: (value) => [moneyMovement(value)],
		}),
	])

	return {
		id: model.string(['id']),
		number: model.string(['number']).replace(/\D/gi, ''),
		maturityDate: model.string(['maturityDate']),
		savingProgramTitle: model.string(['savingProgramTitle']),
		amount: model.null<number>(['amount']),
		upcomingPayment: data?.upcomingPayment
			? {
					date: model.string(['upcomingPayment', 'date']),
					components: model.array(['upcomingPayment', 'components']).map((_, i) => ({
						title: model.string(['upcomingPayment', 'components', i, 'title']),
						amount: model.null<number>(['upcomingPayment', 'components', i, 'amount']),
					})),
			  }
			: null,
		accrualStats: data?.accrualStats
			? {
					components: model.array(['accrualStats', 'components']).map((_, i) => ({
						title: model.string(['accrualStats', 'components', i, 'title']),
						amount: model.null<number>(['accrualStats', 'components', i, 'amount']),
					})),
			  }
			: null,
		additionalDepositConfig: data?.additionalDepositConfig
			? {
					type: model.const(data.additionalDepositConfig.type, {
						BLOCKED: 'BLOCKED',
						ADDITIONAL_AGREEMENT: 'ADDITIONAL_AGREEMENT',
						SIMPLE: 'SIMPLE',
					} as const),
			  }
			: null,
		durationMonths: model.null<number>(['durationMonths']),
		annualProfitPercentage: model.null<number>(['annualProfitPercentage']),
		status: {
			id: model.string(['status', 'id']),
			title: model.string(['status', 'title']),
		},
		paymentType: {
			id: model.string(['paymentType', 'id']),
			title: model.string(['paymentType', 'title']),
		},
		moneyMovements: sort(([a], [b]) => preservedSortType(a, b), Object.entries(moneyMovements)),
		$$component: 'saving' as const,
	}
}
