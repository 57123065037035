import { DefaultValues } from 'helpers/defaultsUtils'

export const savingProgram = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		title: model.string(['title']),
		paymentType: model.string(['paymentType']),
		maturityDate: model.string(['maturityDate']),
		termMonths: model.null<number>(['termMonths']),
		annualProfitPercentage: model.null<number>(['annualProfitPercentage']),
		annualProfitAfterTaxesPercentage: model.null<number>(['annualProfitAfterTaxesPercentage']),
		annualProfitAfterTaxesAmount: model.null<number>(['annualProfitAfterTaxesAmount']),
		profitAmount: model.null<number>(['profitAmount']),
	}
}
