import { DefaultValues } from 'helpers/defaultsUtils'

export const paymentSchedule = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		payments: model.array(['payments']).map((_, i) => ({
			date: model.string(['payments', i, 'date']),
			type: model.string(['payments', i, 'type']),
			amount: model.null<number>(['payments', i, 'amount']),
		})),
	}
}

