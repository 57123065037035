import { DefaultValues } from 'helpers/defaultsUtils'

import { document } from './document'

export const content = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		signatureType: model.const(data.signatureType, {
			SIMPLE: 'SIMPLE',
			QUALIFIED: 'QUALIFIED',
		} as const),
		state: model.const(data.state, {
			NEW: 'NEW',
			ON_SIGNATURE: 'ON_SIGNATURE',
			ERROR: 'ERROR',
			SIGNED: 'SIGNED',
		} as const),
		stateDescription: model.string(['stateDescription']),
		updatedAt: model.string(['updatedAt']),
		documents: model.array(['documents']).map(document),
	}
}
