import { DefaultValues } from 'helpers/defaultsUtils'

import { content } from './content'
import { pagination } from './pagination'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		pagination: pagination(data),
		content: model.array(['content']).map(content),
	}
}
