import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SavingDto } from 'converters/saving'
import { RootState } from 'types/redux'
import { Maybe } from 'types/utils'

import { commonReducers } from '../commonReducers'

export const selectSavingSteps = (id: string) => (state: RootState) => state.saving.steps.data[id]

interface State {
	data: Partial<Record<string, SavingDto.Instruction>>
	loadingId: Maybe<string>
}

const initialState: State = {
	data: {},
	loadingId: null,
}

const singleSlice = createSlice({
	name: 'saving/steps',
	initialState,

	reducers: {
		setData: (state, action: PayloadAction<{ key: string; value: SavingDto.Instruction }>) => {
			state.data[action.payload.key] = action.payload.value
		},

		setLoadingId: (state, action: PayloadAction<State['loadingId']>) => {
			state.loadingId = action.payload
		},

		reset: commonReducers.reset(initialState),
	},
})

const { actions: savingStepsReducerActions, reducer } = singleSlice

export { savingStepsReducerActions, reducer as stepsReducer }
