import { useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import clsx from 'clsx'
import Logo from 'images/logo2.svg'

import { FadeIn } from 'components/common/FadeIn'

import styles from './Image.module.scss'

export type ImageProps = React.DetailedHTMLProps<
	React.ImgHTMLAttributes<HTMLImageElement>,
	HTMLImageElement
> & {
	allowFullScreneView?: boolean
}

export const Image = ({ src, className, allowFullScreneView, ...props }: ImageProps) => {
	const [error, setError] = useState(false)
	const [isViewEnabled, setIsViewEnabled] = useState(false)

	const handleError = useCallback(() => {
		setError(true)
	}, [])

	return (
		<>
			{!error &&
				allowFullScreneView &&
				isViewEnabled &&
				createPortal(
					<div className={styles.viewWrapper} onClick={() => setIsViewEnabled(false)}>
						<FadeIn className={styles.fadeIn}>
							<img src={src} className={styles.viewImage} />
						</FadeIn>
						<div className={styles.bg} />
					</div>,
					document.body,
					'view'
				)}
			<img
				src={error ? Logo : src}
				className={clsx(error && styles.error, className)}
				onError={handleError}
				onClick={allowFullScreneView ? () => setIsViewEnabled(true) : undefined}
				{...props}
			/>
		</>
	)
}
