import { content } from './content'
import { document as _document } from './document'
import { main } from './main'
import { pagination } from './pagination'

export const document = {
	main,
	document: _document,
	pagination,
	content,
}

export namespace DocumentDto {
	export type Main = ReturnType<typeof main>
	export type Document = ReturnType<typeof _document>
	export type Pagination = ReturnType<typeof pagination>
	export type Content = ReturnType<typeof content>
}
