import api from 'api'
import axios, { CancelToken } from 'axios'
import { DocumentDto } from 'converters/document'
import { isEmpty } from 'ramda'
import { archiveReducerActions } from 'reducers/document/archive'
import { paginationReducerActions } from 'reducers/document/pagination'
import { signingReducerActions } from 'reducers/document/signing'
import {
	selectQualifiedPackageOnSignature,
	selectSimplePackageOnSignature,
} from 'selectors/document'
import { TUtils } from 'types'
import { TAction } from 'types/redux'

import { notice } from 'components/common/Notice'
import { packageLs } from 'helpers/packageLs'
import { PollingRequest } from 'helpers/pollingRequest'

/**
 * Проверяет наличие документов на подписи и показывает уведомление,
 * если документы перешли в подписаные
 */
const handlePackageLs = ({ data }: { data: DocumentDto.Content[] }) => {
	packageLs
		.getAll()
		.forEach(
			(id) => data.find((item) => item.id === id)?.state !== 'ON_SIGNATURE' && packageLs.remove(id)
		)

	data.forEach(
		({ id, state, signatureType }) =>
			state === 'ON_SIGNATURE' &&
			signatureType === 'QUALIFIED' &&
			!packageLs.has(id as string) &&
			packageLs.set(id as string)
	)

	packageLs.getAll().forEach(
		(packageId) =>
			!data.some(({ id }) => id === packageId) &&
			notice({
				type: 'success',
				title: 'Документ подписан',
			})
	)
}

export const documentActionsPolling = {
	signingPackages: new PollingRequest()
		.setRequest(
			() => (cancelToken, dispatch) =>
				dispatch(
					documentActions.pollingSigningPackages({
						cancelToken,
						keepPollingIfPackagesOnSignatureFound: false,
					})
				)
		)
		.setIntervalTime(5000),

	checkQualifiedPackages: new PollingRequest()
		.setRequest(
			() => (cancelToken, dispatch) =>
				dispatch(
					documentActions.pollingSigningPackages({
						cancelToken,
						keepPollingIfPackagesOnSignatureFound: true,
					})
				)
		)
		.setIntervalTime(5000),
}

export const documentActions = {
	/**
	 * На странице документов запускается поллинг с параметром
	 * `keepPollingIfPackagesOnSignatureFound = true` на случай если
	 * пользователь подпишет пакет с типом QUALIFIED.
	 * Если пользователь находится на любой другой странице,
	 * запускается поллинг с параметром `keepPollingIfPackagesOnSignatureFound = false`.
	 */
	pollingSigningPackages:
		({
			cancelToken,
			keepPollingIfPackagesOnSignatureFound,
		}: {
			cancelToken: CancelToken
			keepPollingIfPackagesOnSignatureFound: boolean
		}): TAction<Promise<void>> =>
		async (dispatch, getState) => {
			const isAnyPackagesFound = !isEmpty(getState().document.signing.ids)
			const packageOnSignature = selectQualifiedPackageOnSignature(getState())

			const isContinuePolling = !!packageOnSignature && keepPollingIfPackagesOnSignatureFound

			if (!isContinuePolling && isAnyPackagesFound) {
				documentActionsPolling.signingPackages.stopPolling()
				return
			}

			try {
				const {
					data: { content },
				} = await api.document.search(
					{ page: 0, size: 100, filter: { state: ['ERROR', 'NEW', 'ON_SIGNATURE'] } },
					cancelToken
				)

				dispatch(signingReducerActions.setData(content))

				handlePackageLs({ data: content })
			} catch {
				//
			}
		},

	getSigningPackages: (): TAction<Promise<void>> => async (dispatch, getState) => {
		const shouldLoading = getState().document.signing.status !== 'fulfilled'

		try {
			if (shouldLoading) dispatch(signingReducerActions.setStatus('pending'))

			const { data } = await api.document.search({
				filter: { state: ['ERROR', 'NEW', 'ON_SIGNATURE'] },
			})

			dispatch(paginationReducerActions.setData(data.pagination))
			dispatch(signingReducerActions.setData(data.content))
			dispatch(signingReducerActions.setStatus('fulfilled'))

			handlePackageLs({ data: data.content })
		} catch (error) {
			dispatch(signingReducerActions.setStatus('rejected'))

			notice({
				title: 'Ошибка',
				message: 'Не удалось загрузить документы на подпись',
			})
		}
	},

	getArchivePackages:
		(params: TUtils.Pagination = { page: 0, size: 5 }): TAction<Promise<void>> =>
		async (dispatch) => {
			try {
				const { data } = await api.document.search({
					...params,
					filter: { state: ['SIGNED'] },
				})

				dispatch(paginationReducerActions.setData(data.pagination))
				dispatch(archiveReducerActions.setData(data.content))
				dispatch(archiveReducerActions.setStatus('fulfilled'))
			} catch (error) {
				dispatch(archiveReducerActions.setStatus('rejected'))

				notice({
					title: 'Ошибка',
					message: 'Не удалось загрузить архивные документы',
				})
			}
		},

	startSimpleSigningOperation:
		({ id }: Pick<DocumentDto.Content, 'id'>): TAction<Promise<void>> =>
		async (dispatch, getState) => {
			const packageOnSignature = selectSimplePackageOnSignature(getState())
			/**
			 * Необходимо для кейса, когда пользователь закрыл
			 * модальное окно подтверждения и открыл заново
			 */
			if (packageOnSignature) return Promise.resolve()

			try {
				await api.document.sign({ id })

				dispatch(signingReducerActions.setPackageState({ id, value: 'ON_SIGNATURE' }))
			} catch (error: any) {
				if (axios.isCancel(error)) return

				notice({
					message: error?.response?.data?.message ?? 'Не удалось инициализировать операцию ПЭП',
				})

				throw error
			}
		},

	startQualifiedSigningOperation:
		({ id }: Pick<DocumentDto.Content, 'id'>): TAction<Promise<void>> =>
		async (dispatch, getState) => {
			const packageOnSignature = selectQualifiedPackageOnSignature(getState())
			const isPackageOnSignature = packageLs.has(id)
			/**
			 * Необходимо для кейса, когда пользователь закрыл
			 * модальное окно подтверждения и открыл заново
			 */
			if (packageOnSignature || isPackageOnSignature) return Promise.resolve()

			try {
				await api.document.sign({ id })

				packageLs.set(id)
				dispatch(signingReducerActions.setPackageState({ id, value: 'ON_SIGNATURE' }))
			} catch (error: any) {
				if (axios.isCancel(error)) return

				notice({
					message: error?.response?.data?.message ?? 'Не удалось инициализировать операцию ПЭП',
				})

				throw error
			}
		},

	confirmSign:
		({ code, onSuccess }: { code: string; onSuccess?: () => void }): TAction<Promise<void>> =>
		async (dispatch, getState) => {
			const packageOnSignature = selectSimplePackageOnSignature(getState())

			if (!packageOnSignature) throw new Error()

			try {
				const { data } = await api.document.confirm({ id: packageOnSignature.id, code })

				if (data?.result === 'DECLINED') {
					throw new Error('Неправильный код или срок действия СМС истек')
				}

				dispatch(signingReducerActions.removePackage(packageOnSignature.id))

				notice({
					title: 'Операция завершена',
					type: 'success',
					message: 'Документы успешно подписаны',
				})

				onSuccess?.()
			} catch (error: any) {
				if (axios.isCancel(error)) return

				throw new Error(error?.response?.data?.message || error?.message || '')
			}
		},
}
