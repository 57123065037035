export const normalizeByField = <
	T extends Record<string, any>,
	K extends keyof T,
	F extends (curr: T) => T
>(
	array: F extends never ? T[] : ReturnType<NonNullable<F>>[],
	field: K,
	transform?: F
): Record<string, F extends never ? T : ReturnType<F>> =>
	array.reduce(
		(acc, curr) => Object.assign(acc, { [curr[field]]: transform ? transform(curr) : curr }),
		{}
	)
