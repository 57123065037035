import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoanDto } from 'converters/loan'
import { TRedux } from 'types'
import { EntityStatus } from 'types/redux'

import { commonReducers } from '../commonReducers'

const loanListAdapter = createEntityAdapter<LoanDto.List>()

export const loanListSelector = loanListAdapter.getSelectors<TRedux.RootState>(
	(state) => state.loan.list
)

const initialState = loanListAdapter.getInitialState({
	status: 'pending' as EntityStatus,
	isInitialized: false,
})

const listSlice = createSlice({
	name: 'loan/list',
	initialState,

	reducers: {
		setData: loanListAdapter.setAll,

		setInitialized: (state, action: PayloadAction<boolean>) => {
			state.isInitialized = action.payload
		},

		setStatus: commonReducers.setStatus,
		reset: commonReducers.reset(initialState),
	},
})

const { actions: loanListReducerActions, reducer } = listSlice

export { loanListReducerActions, reducer as listReducer }
