import { DefaultValues } from 'helpers/defaultsUtils'

const option = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		value: model.string(['value']),
	}
}

export const loan = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		minAmount: model.null<number>(['minAmount']),
		maxAmount: model.null<number>(['maxAmount']),
		lendingTermsUrl: model.string(['lendingTermsUrl']),
		purposeOptions: model.array(['purposeOptions']).map(option),
		durationOptions: model.array(['durationOptions']).map(option),
	}
}
