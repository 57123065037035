import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { DocumentDto } from 'converters/document'
import { commonReducers } from 'reducers/commonReducers'
import { EntityStatus, RootState } from 'types/redux'

const adapter = createEntityAdapter<DocumentDto.Content>()

export const documentArchiveSelector = adapter.getSelectors<RootState>(
	(state) => state.document.archive
)

const initialState = adapter.getInitialState({
	status: 'pending' as EntityStatus,
})

const archiveSlice = createSlice({
	name: 'document/archive',
	initialState,
	reducers: {
		setData: adapter.setAll,
		removePackage: adapter.removeOne,
		setStatus: commonReducers.setStatus,
		reset: commonReducers.reset(initialState),
	},
})

const { actions, reducer } = archiveSlice

export { actions as archiveReducerActions, reducer as archiveReducer }
