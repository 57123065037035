import * as React from 'react'
import type { TabListProps as ReactTabListProps } from 'react-tabs'
import { TabList as ReactTabList } from 'react-tabs'
import clsx from 'clsx'

import styles from './TabList.module.scss'

export type TabListProps = ReactTabListProps & { className?: string }

const TabList = ({
	children,
	className,
	...restOfProps
}: React.PropsWithChildren<TabListProps & { tabsRole?: string }>) => {
	return (
		<ReactTabList
			{...(restOfProps as Omit<typeof restOfProps, 'ref'>)}
			className={clsx(styles.wrap, {
				[className!]: !!className,
			})}
		>
			{children}
		</ReactTabList>
	)
}

TabList.tabsRole = 'TabList'

export { TabList }
