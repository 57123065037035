import cx from 'clsx'
import logo from 'images/only-logo.svg'

import { Image } from 'components/common'

import styles from './PageLoading.module.scss'

export type PageLoadingProps = {
	min?: boolean
	max?: boolean
	white?: boolean
	fullHeight?: boolean
	fullVh?: boolean
	className?: string
	useLogo?: boolean
}

export const PageLoading = ({ min, useLogo, max, fullHeight, fullVh, white, className }: PageLoadingProps) =>
	useLogo ? (
		<div
			className={cx(
				styles.logoLoading,
				{
					[styles.fullHeight]: fullHeight,
					[styles.fullVh]: fullVh,
				},
				className
			)}
		>
			<Image src={logo} className={cx(styles.logo)} />
		</div>
	) : (
		<div
			className={cx(styles.loading, {
				[styles.min]: min,
				[styles.max]: max,
				[styles.white]: white,
				[styles.fullHeight]: fullHeight,
				[styles.fullVh]: fullVh,
				[className!]: !!className,
			})}
		>
			<div className={styles.ring}>
				<div />
				<div />
				<div />
				<div />
			</div>
		</div>
	)
