import axios, { CancelToken } from 'axios'
import {
	productShowcase as productShowcaseConverter,
	ProductShowcaseDto,
} from 'converters/productShowcase'

export namespace ProductsShowcaseApi {
	export type SearchProducts = ProductShowcaseDto.Product[]
	export type GetSaving = ProductShowcaseDto.Saving
	export type GetSavingPrograms = ProductShowcaseDto.SavingProgram[]
	export type GetLoan = ProductShowcaseDto.Loan
}

export const productsShowcase = {
	searchProducts: (cancelToken?: CancelToken) =>
		axios
			.get<ProductsShowcaseApi.SearchProducts>('/v3/products-showcase/products', { cancelToken })
			.then((response) =>
				Object.assign({}, response, { data: response.data.map(productShowcaseConverter.product) })
			),

	getSaving: (cancelToken?: CancelToken) =>
		axios
			.get<ProductsShowcaseApi.GetSaving>('/v3/products-showcase/products/saving', { cancelToken })
			.then((response) =>
				Object.assign({}, response, { data: productShowcaseConverter.saving(response.data) })
			),

	getSavingPrograms: ({
		cancelToken,
		amount,
		paymentType,
		duration,
	}: {
		amount: number | string
		paymentType: string
		duration: number | string
		cancelToken?: CancelToken
	}) =>
		axios
			.get<ProductsShowcaseApi.GetSavingPrograms>(
				'/v3/products-showcase/products/saving/programs',
				{
					cancelToken,
					$$requestName: 'productShowcase/getSavingPrograms',
					params: { amount, paymentType, duration },
				}
			)
			.then((response) =>
				Object.assign({}, response, {
					data: response.data.map(productShowcaseConverter.savingProgram),
				})
			),

	getLoan: (cancelToken?: CancelToken) =>
		axios
			.get<ProductsShowcaseApi.GetLoan>('/v3/products-showcase/products/loan', { cancelToken })
			.then((response) =>
				Object.assign({}, response, { data: productShowcaseConverter.loan(response.data) })
			),
}
