import { PropsWithChildren } from 'react'
import clsx from 'clsx'
import { TMedia } from 'types'

import styles from './StyledBlock.module.scss'

export interface StyledBlockProps extends PropsWithChildren<unknown> {
	theme?: 'dark' | 'light'
	className?: string
	indent?: TMedia.Indents
	padding?: TMedia.Indents
	fit?: boolean
	attrs?: React.HTMLAttributes<HTMLDivElement>
}

const StyledBlock = (props: StyledBlockProps) => {
	const indentClassname = props.indent ? styles[props.indent] : undefined
	const paddingClassname = props.padding ? styles[`padding-${props.padding}`] : undefined

	return (
		<div
			className={clsx(styles.wrap, paddingClassname, indentClassname, {
				[props.className!]: props.className,
				[styles.fit]: props.fit,
				[styles.dark]: props.theme === 'dark',
				[styles.light]: props.theme === 'light',
			})}
			{...props.attrs}
		>
			{props.children}
		</div>
	)
}

StyledBlock.defaultProps = {
	theme: 'light',
	attrs: {},
}

export { StyledBlock }
