import { DefaultValues } from 'helpers/defaultsUtils'

export const contacts = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		phone: model.string(['phone']),
		whatsappUrl: model.string(['whatsappUrl']),
	}
}
