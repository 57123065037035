import clsx from 'clsx'

import styles from './Background.module.scss'

export type BackgroundProps = {
	theme: 'gray'
}

export const Background = (props: BackgroundProps) => {
	const { theme } = props

	return (
		<div
			className={clsx(styles.background, {
				[styles.gray]: theme === 'gray',
			})}
		/>
	)
}
