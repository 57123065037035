import { DefaultValues } from 'helpers/defaultsUtils'

export const upcomingPaymentBlock = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		accountBalance: model.null<number>(['accountBalance']),
		upcomingPayment: {
			comment: model.string(['upcomingPayment', 'comment']),
			date: model.string(['upcomingPayment', 'date']),
			finalAmount: model.null<number>(['upcomingPayment', 'finalAmount']),
			amount: model.null<number>(['upcomingPayment', 'amount']),
			mainDebt: model.null<number>(['upcomingPayment', 'mainDebt']),
			interest: model.null<number>(['upcomingPayment', 'interest']),
			membershipFees: model.null<number>(['upcomingPayment', 'membershipFees']),
			penalties: model.null<number>(['upcomingPayment', 'penalties']),
			fines: model.null<number>(['upcomingPayment', 'fines']),
			commissions: model.null<number>(['upcomingPayment', 'commissions']),
		},
	}
}
