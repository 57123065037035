import { list } from './list'
import { moneyMovement } from './moneyMovement'
import { participant } from './participant'
import { pledge } from './pledge'
import { single } from './single'

export const loan = {
	list,
	single,
	participant,
	pledge,
	moneyMovement,
}

export namespace LoanDto {
	export type List = ReturnType<typeof list>
	export type Single = ReturnType<typeof single>
	export type Participant = ReturnType<typeof participant>
	export type MoneyMovement = ReturnType<typeof moneyMovement>
	export type Pledge = ReturnType<typeof pledge>
}
