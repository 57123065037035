import { DefaultValues } from 'helpers/defaultsUtils'

export const signingCertificate = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		subject: model.string(['subject']),
		notValidAfter: model.string(['notValidAfter']),
	}
}
