import { brief } from './brief'
import { main } from './main'

export const referallProgram = {
	brief,
	main,
}

export namespace ReferallProgramDto {
	export type Brief = ReturnType<typeof brief>
	export type Main = ReturnType<typeof main>
}
