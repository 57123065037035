import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import Logo from 'images/logo.svg'

import { Image } from 'components/common'
import { Container, Row } from 'components/common/Grid'

import styles from './Header.module.scss'

export type HeaderProps = {
	plain?: boolean
}

export const Header = (props: HeaderProps) => {
	const navigate = useNavigate()

	const handleChangeRoute = () => {
		if (history) {
			navigate('/')
		}
	}

	return (
		<header>
			<Container
				className={clsx({
					[styles.container]: props.plain,
				})}
			>
				<Row
					className={clsx({
						[styles.row]: props.plain,
					})}
				>
					{props.plain ? (
						<a
							href='https://credit.club/investors/fond'
							className={styles.logo}
							target='_blank'
							rel='noopener noreferrer'
						>
							<Image src={Logo} alt='' />
						</a>
					) : (
						<div onClick={handleChangeRoute} className={styles.logo}>
							<Image src={Logo} alt='' />
						</div>
					)}
				</Row>
			</Container>
		</header>
	)
}
