import axios, { CancelToken } from 'axios'
import { requisites as requisitesConverter, RequisitesDto } from 'converters/requisites'
import { saving as savingConverter, SavingDto } from 'converters/saving'

export namespace SavingApi {
	export type Search = SavingDto.List[]
	export type Get = SavingDto.Single
	export type PaymentSchedule = SavingDto.PaymentSchedule
	export type GetPaymentRequisites = RequisitesDto.Main
	export type GetRefillRequisites = RequisitesDto.Main
	export type Create = void
}

export const saving = {
	search: (cancelToken?: CancelToken) =>
		axios
			.get<SavingApi.Search>(`/v3/products/savings`, {
				cancelToken,
			})
			.then((response) =>
				Object.assign({}, response, { data: response.data.map(savingConverter.list) })
			),

	create: (payload: {
		amount: number
		durationMonths: number
		paymentType: string
		programId: string
	}) => axios.post<SavingApi.Create>(`/v3/products/savings`, payload),

	getPaymentRequisites: ({
		id,
		type,
	}: {
		id: string
		type: string
	}) =>
		axios
			.get<SavingApi.GetPaymentRequisites>(`/v3/products/savings/${id}/payment-requisites/${type}`)
			.then((response) =>
				Object.assign({}, response, { data: requisitesConverter.main(response.data) })
			),

	refill: ({ id, amount }: { id: string; amount: number }) =>
		axios.post(`/v3/products/savings/${id}/deposit`, { amount }),

	instructions: ({ id }: { id: string }) =>
		axios
			.get(`/v3/products/savings/${id}/instruction`)
			.then((response) =>
				Object.assign({}, response, { data: savingConverter.instruction(response.data) })
			),

	paymentSchedule: ({ id }: { id: string }) =>
		axios
			.get<SavingApi.PaymentSchedule>(`/v3/products/savings/${id}/payment-schedule`)
			.then((response) =>
				Object.assign({}, response, { data: savingConverter.paymentSchedule(response.data) })
			),

	get: ({ id }: { id: string }, cancelToken?: CancelToken) =>
		axios
			.get<SavingApi.Get>(`/v3/products/savings/${id}`, {
				cancelToken,
				$$requestName: 'savings.get',
			})
			.then((response) =>
				Object.assign({}, response, { data: savingConverter.single(response.data) })
			),
}
