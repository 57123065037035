import { DefaultValues } from 'helpers/defaultsUtils'

export const participant = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		title: model.string(['title']),
		roles: model.array(['roles']).map((_, i) => ({
			id: model.string(['roles', i, 'id']),
			title: model.string(['roles', i, 'title']),
		})),
	}
}
