import { DefaultValues } from 'helpers/defaultsUtils'

export const list = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		createdAt: model.string(['createdAt']),
		number: model.string(['number']).replace(/\D/gi, ''),
		loanAmount: model.null<number>(['loanAmount']),
		loanDurationMonths: model.null<number>(['loanDurationMonths']),
		upcomingPayment: data.upcomingPayment
			? {
					comment: model.string(['upcomingPayment', 'comment']),
					date: model.string(['upcomingPayment', 'date']),
					finalAmount: model.null<number>(['upcomingPayment', 'finalAmount']),
			  }
			: null,
		status: {
			id: model.string(['status', 'id']),
			title: model.string(['status', 'title']),
		},
		type: model.const(data.type, {
			APPLICATION: 'APPLICATION',
			LEAD: 'LEAD',
			LOAN: 'LOAN',
		} as const),
		$$component: 'loan' as const,
	}
}
