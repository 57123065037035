import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Onboarding } from 'converters/onboarding'
import { TUtils } from 'types'
import { EntityStatus, RootState } from 'types/redux'

import { commonReducers } from '../commonReducers'

export const selectIsOnboardingLoading = createSelector(
	[(state: RootState) => state.onboarding.data, (state: RootState) => state.onboarding.status],
	(data, status) => !data && status === 'pending'
)

export const selectShouldOnboardingShow = createSelector(
	[(state: RootState) => state.onboarding.data, (state: RootState) => state.onboarding.status],
	(data, status) => data && status === 'fulfilled'
)

interface State {
	data: TUtils.Maybe<Onboarding.Main>
	status: EntityStatus
}

const initialState: State = {
	data: null,
	status: 'pending',
}

const onboardingSlice = createSlice({
	name: 'onboarding',
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<State['data']>) => {
			state.data = action.payload
		},

		setStatus: (state, action: PayloadAction<EntityStatus>) => {
			state.status = action.payload
		},

		reset: commonReducers.reset(initialState),
	},
})

const { actions: onboardingReducerActions, reducer: onboardingReducer } = onboardingSlice

export { onboardingReducerActions, onboardingReducer }
