import { isValidElement } from 'react'
import { MEDIA_BREAKPOINTS } from 'const/mediaBreakpoints'
import { TUtils } from 'types'

const beforeunloadListener = (e: BeforeUnloadEvent) => {
	e.preventDefault()
	e.returnValue = ''
	return ''
}

export default {
	bytesToSize: (bytes?: number | null) => {
		if (bytes == null) return ''

		const sizes = ['Byte', 'KB', 'MB', 'GB', 'TB']

		if (bytes === 0) return 'n/a'

		const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10)
		if (i === 0) return `${bytes} ${sizes[i]}`

		return `${(bytes / Math.pow(1024, i)).toFixed(1)}\u00A0${sizes[i]}`
	},

	makeCancelable: <T extends Promise<any>>(promise: T) => {
		let _hasCanceled = false

		const wrappedPromise = new Promise<T>((resolve, reject) => {
			promise.then(
				(value) => (_hasCanceled ? reject({ isCanceled: true }) : resolve(value)),
				(err) => (_hasCanceled ? reject({ isCanceled: true }) : reject(err))
			)
		})

		return {
			promise: wrappedPromise as T,
			cancel() {
				_hasCanceled = true
			},
		}
	},

	useBeforeUnload: (start = true) => {
		if (start) {
			window.addEventListener('beforeunload', beforeunloadListener)
		} else {
			window.removeEventListener('beforeunload', beforeunloadListener)
		}
	},

	adaptText: (num: number, words: [string, string, string]): string => {
		const count: number = num % 100
		const _count: number = count % 10
		if (count > 10 && count < 20) {
			return words[2]
		}
		if (_count > 1 && _count < 5) {
			return words[1]
		}
		if (_count === 1) {
			return words[0]
		}
		return words[2]
	},

	defaultOr: <T, D = string>(value: T, defaultValue: D = '—' as never): NonNullable<T> | D => {
		if (
			(typeof value === 'string' && value !== '') ||
			(typeof value === 'object' &&
				value !== null &&
				!Array.isArray(value) &&
				isValidElement(value)) ||
			Number.isFinite(value) ||
			(Array.isArray(value) && value.length)
		)
			return value as NonNullable<T>

		return defaultValue
	},

	upperFirst: (value?: string | null) => {
		if (!value) return value

		return `${value[0].toUpperCase()}${value.slice(1)}`
	},

	unbreakableSpace: (value?: string | null) => {
		if (typeof value !== 'string') return value
		return value.replace(/\s/g, `\u00a0`)
	},

	formatPhone: (phone?: string, options?: { unbreakableSpace?: boolean }) => {
		if (!phone || !/^[0-9/+-\s]*$/.test(phone)) return ''

		let formattedPhone, phone1, phone2, phone3, phone4

		if (phone.length === 7) {
			phone1 = phone.slice(0, 3)
			phone2 = phone.slice(3, 5)
			phone3 = phone.slice(5, 7)
			formattedPhone = `+7 ${phone1}-${phone2}-${phone3}`
		} else if (phone.length === 10) {
			phone1 = phone.slice(0, 3)
			phone2 = phone.slice(3, 6)
			phone3 = phone.slice(6, 8)
			phone4 = phone.slice(8, 10)
			formattedPhone = `+7 ${phone1} ${phone2}-${phone3}-${phone4}`
		} else {
			formattedPhone = `+7 ${phone}`
		}

		return options?.unbreakableSpace ? formattedPhone.replace(/\s/g, `\u00a0`) : formattedPhone
	},

	join: function <T extends any[]>(data: T, separator = ' ') {
		return data.filter(Boolean).join(separator)
	},

	download: (url?: string): Promise<void> => {
		if (!url) return Promise.reject()

		return new Promise((resolve) => {
			const element = document.createElement('a')
			element.href = url
			element.target = '_blank'
			element.style.display = 'none'
			document.body.appendChild(element)
			const timeout = setTimeout(() => {
				element.click()
				document.body.removeChild(element)
				clearTimeout(timeout)
				resolve()
			}, 500)
		})
	},

	getMedia: (width: number) => {
		if (width > MEDIA_BREAKPOINTS.TABLET) {
			return 'DESKTOP'
		} else if (width > MEDIA_BREAKPOINTS.MOBILE && width < MEDIA_BREAKPOINTS.TABLET) {
			return 'TABLET'
		}
		return 'MOBILE'
	},

	last: <T extends any[]>(array: T): (T extends (infer U)[] ? U : undefined) | undefined => {
		return array[array.length - 1]
	},

	saveJsonParse: <T = any>(value: any): TUtils.Maybe<T> => {
		try {
			const parsed = JSON.parse(value)
			return parsed
		} catch {
			return null
		}
	},

	fill: <T1 extends number, T2>(length: T1, array: T2[], filler = {} as T2): T2[] => {
		return array.length ? array : Array(length).fill(filler)
	},

	getPersonFullName: (person?: TUtils.Maybe<Record<string, any>>, min?: boolean) => {
		if (!person) return ''

		const { name, surname, patronymic } = person

		if (min) {
			return `${surname} ${name[0].toUpperCase()}.${patronymic[0].toUpperCase()}.`.trim()
		} else {
			return [surname, name, patronymic].filter(Boolean).join(' ')
		}
	},
}
