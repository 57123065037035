import ls from 'localstorage-slim'
import dissoc from 'ramda/src/dissoc'

const key = 'package-on-signature'

type Value = Record<string, string>

export const packageLs = {
	getAll: () => Object.keys(ls.get<Value>(key) ?? {}),
	has: (id: string) => !!ls.get<Value>(key)?.[id],
	set: (id: string) => ls.set<Value>(key, Object.assign(ls.get<Value>(key) ?? {}, { [id]: id })),
	remove: (id: string) => ls.set<Value>(key, dissoc(id, ls.get<Value>(key) ?? {})),
}
