import { PropsWithChildren } from 'react'
import clsx from 'clsx'

import styles from './NavItemIcon.module.scss'

export interface NavItemIconProps extends PropsWithChildren<unknown> {
	className?: string
}

export const NavItemIcon = (props: NavItemIconProps) => {
	return <div className={clsx(styles.wrap, props.className)}>{props.children}</div>
}
