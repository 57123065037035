import { PropsWithChildren } from 'react'
import clsx from 'clsx'

import styles from './Nav.module.scss'

export type NavProps = PropsWithChildren<{
	children?: React.ReactNode
	className?: string
}>

export const Nav = (props: NavProps) => {
	return (
		<ul
			className={clsx(styles.wrap, {
				[props.className!]: !!props.className,
			})}
		>
			{props.children}
		</ul>
	)
}
