import axios, { CancelToken } from 'axios'
import { loan as loanConverter, LoanDto } from 'converters/loan'
import { requisites as requisitesConverter, RequisitesDto } from 'converters/requisites'

export namespace LoanApi {
	export type Search = LoanDto.List[]
	export type Get = LoanDto.Single
	export type GetRequisites = RequisitesDto.Main
	export type Create = void
}

const baseUrl = `/v3/products/loans`

export const loan = {
	search: (cancelToken?: CancelToken) =>
		axios
			.get<LoanApi.Search>(baseUrl, {
				cancelToken,
			})
			.then((response) =>
				Object.assign({}, response, { data: response.data.map(loanConverter.list) })
			),

	create: (payload: { amount: number; durationMonths: number; purpose: string }) =>
		axios.post<LoanApi.Create>(baseUrl, payload),

	get: ({ id }: { id: string }, cancelToken?: CancelToken) =>
		axios
			.get<LoanApi.Get>(`${baseUrl}/${id}`, {
				cancelToken,
				$$requestName: 'loans.get',
			})
			.then((response) =>
				Object.assign({}, response, { data: loanConverter.single(response.data) })
			),

	uploadDocuments: ({ id, files }: { id: string; files: FormData }) =>
		axios.post(`${baseUrl}/${id}/documents`, files, {
			headers: { 'Content-Type': 'multipart/form-data' },
		}),

	deleteDocument: ({ loanId, documentId }: { loanId: string; documentId: string }) =>
		axios.delete(`${baseUrl}/${loanId}/documents/${documentId}`),

	getRequisites: ({ id }: { id: string }) =>
		axios
			.get<LoanApi.GetRequisites>(`${baseUrl}/${id}/payment-requisites`)
			.then((response) =>
				Object.assign({}, response, { data: requisitesConverter.main(response.data) })
			),
}
