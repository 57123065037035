import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BonusAccountDto } from 'converters/bonusAccount'
import { IdentificationDto } from 'converters/identification'
import { Profile } from 'converters/profile'
import { ReferallProgramDto } from 'converters/referallProgram'
import { TUtils } from 'types'
import { RootState } from 'types/redux'

import { normalizeByField } from 'helpers/normalize'

import { commonReducers } from './commonReducers'

export const selectProfileLinkEntities = createSelector(
	(state: RootState) => state.profile.data?.linkedEntities,
	(v) => normalizeByField(v ?? [], 'id')
)

export const selectBonusAccount = (state: RootState) => state.profile.bonusAccount

export const selectReferallProgramBrief = createSelector(
	(state: RootState) => state.profile.referallPrograms.brief,
	(v) => (v ? Object.entries(v).map(([, value]) => value) : [])
)

export const selectReferallProgramMain = (state: RootState) => state.profile.referallPrograms.main

export const selectProfile = createSelector(
	(state: RootState) => state.profile.data,
	(v) => v
)

export const selectSelfieIdentification = createSelector(
	(state: RootState) => state.profile.selfieIdentification,
	(v) => v
)

interface State {
	data: TUtils.Maybe<Profile.Main>
	identification: TUtils.Maybe<IdentificationDto.Main>
	selfieIdentification: TUtils.Maybe<'IDENTIFIED' | 'NOT_IDENTIFIED'>
	bonusAccount: TUtils.Maybe<BonusAccountDto.Main>
	referallPrograms: {
		brief: TUtils.Maybe<Record<string, ReferallProgramDto.Brief>>
		main: TUtils.Maybe<Record<string, ReferallProgramDto.Main>>
	}
	isLoading: boolean
}

const initialState: State = {
	data: null,
	identification: null,
	selfieIdentification: null,
	bonusAccount: null,
	referallPrograms: {
		brief: null,
		main: null,
	},
	isLoading: false,
}

const profileSlice = createSlice({
	name: 'profile',
	initialState,

	reducers: {
		setData: (state, action: PayloadAction<State['data']>) => {
			state.data = action.payload
		},

		setIdentification: (state, action: PayloadAction<State['identification']>) => {
			state.identification = action.payload
		},

		setSelfieIdentification: (state, action: PayloadAction<Profile.SelfieIdentification>) => {
			state.selfieIdentification = action.payload.status
		},

		setBonusAccount: (state, action: PayloadAction<State['bonusAccount']>) => {
			state.bonusAccount = action.payload
		},

		setReferallProgramBrief: (state, action: PayloadAction<ReferallProgramDto.Brief[]>) => {
			state.referallPrograms.brief = action.payload.reduce((acc, curr) => {
				acc[curr.id] = curr
				return acc
			}, {} as NonNullable<State['referallPrograms']['brief']>)
		},

		selectReferallProgramMain: (state, action: PayloadAction<ReferallProgramDto.Main>) => {
			if (!state.referallPrograms.main) state.referallPrograms.main = {}
			state.referallPrograms.main[action.payload.id] = action.payload
		},

		setLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload
		},

		reset: commonReducers.reset(initialState),
	},
})

const { actions: profileReducerActions, reducer } = profileSlice

export { profileReducerActions, reducer as profileReducer }
