import { DefaultValues } from 'helpers/defaultsUtils'

export const accountStats = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		totalReferralIncomeAmount: model.null<number>(['totalReferralIncomeAmount']),
		totalReferralsCount: model.null<number>(['totalReferralsCount']),
	}
}
