import { memo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ym, { YMInitializer } from 'react-yandex-metrika'
import { keycloak } from 'client/keycloak/instance'

const COUNTER_ID = 54317247

export const MetrikaInitializer = memo((): JSX.Element | null => {
	const accounts =
		window.__env__.BUILD_MODE === 'production' || window.location.search.includes('_ym_debug=1')
			? [COUNTER_ID]
			: []
	return <YMInitializer accounts={accounts} />
})

export function useMetrikaLogger() {
	const location = useLocation()
	const pathname = location.pathname
	const search = location.search
	const hash = location.hash

	useEffect(() => {
		const options = { pathname, search, hash }
		ym('hit', `${pathname}${search}${hash}`, options)
	}, [pathname, search, hash])

	const tokenUserId = keycloak.tokenParsed?.sub || null

	useEffect(() => {
		if (!tokenUserId) return
		ym('setUserID', `${tokenUserId}`)
	}, [tokenUserId])
}

// export function metrikaReachGoal(goal: string) {
// 	ym('reachGoal', goal)
// }
