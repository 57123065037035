import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { MEDIA_BREAKPOINTS } from 'const/mediaBreakpoints'
import { useMedia } from 'hooks'
import ActiveChat from 'images/icons/chat/active.svg'
import NormalChat from 'images/icons/chat/normal.svg'
import ActiveDoc from 'images/icons/doc/active.svg'
import NormalDoc from 'images/icons/doc/normal.svg'
import ActiveHome from 'images/icons/home/active.svg'
import NormalHome from 'images/icons/home/normal.svg'
import ActiveProfile from 'images/icons/profile/active.svg'
import NormalProfile from 'images/icons/profile/normal.svg'
import Logo from 'images/logo.svg'
import { urls } from 'routes/urls'
import { TRedux } from 'types'

import { Image } from 'components/common'
import { Container, Row } from 'components/common/Grid'
import { Nav, NavItem, NavItemIcon, NavItemText } from 'components/common/Nav'

import styles from './Navigation.module.scss'

interface NavItems {
	label: string
	url?: string
	iconClassName?: string
	labelClassName?: string
	onClick?: React.ComponentProps<typeof NavItem>['onClick']
	active?: boolean
	icon?: string
	activeIcon?: string
	visibleOn?: Array<keyof typeof MEDIA_BREAKPOINTS>
}

const navItems: NavItems[] = [
	{
		label: 'Главная',
		url: urls.main.path,
		iconClassName: styles.home,
		active: true,
		icon: NormalHome,
		activeIcon: ActiveHome,
	},
	{
		label: 'Документы',
		url: urls.docs.path,
		iconClassName: styles.docs,
		active: true,
		icon: NormalDoc,
		activeIcon: ActiveDoc,
	},
	{
		label: 'Чат',
		url: urls.chat.path,
		iconClassName: styles.chat,
		active: true,
		icon: NormalChat,
		activeIcon: ActiveChat,
	},
	{
		label: 'Профиль',
		url: urls.profile.path,
		iconClassName: styles.profile,
		active: true,
		icon: NormalProfile,
		activeIcon: ActiveProfile,
	},
	// {
	// 	label: 'Выйти',
	// 	labelClassName: styles.logout,
	// 	visibleOn: ['DESKTOP'],
	// 	onClick: () => keycloak.logout(),
	// },
]

export const Navigation = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const media = useMedia()
	const awaitingSignature = useSelector(
		(state: TRedux.RootState) => !!state.document.signing.ids.length
	)

	const handleChangeRoute = () => {
		navigate('/')
	}

	return (
		<header>
			<Container>
				<Row className={styles.row}>
					<div onClick={handleChangeRoute} className={styles.logo}>
						<Image src={Logo} alt='' />
					</div>
					<div className={styles.nav}>
						<Nav>
							{navItems
								.filter(({ visibleOn }) => visibleOn?.includes(media) ?? true)
								.map((navItem, index) => (
									<NavItem key={index} to={navItem.url} onClick={navItem.onClick}>
										{!!(navItem.activeIcon && navItem.icon) && (
											<NavItemIcon className={styles.navItemIcon}>
												<div className={navItem.iconClassName}>
													<Image
														src={
															navItem.url === location.pathname ? navItem.activeIcon : navItem.icon
														}
													/>
												</div>
												{navItem.url === urls.docs.path && awaitingSignature && (
													<div className={styles.dot} />
												)}
											</NavItemIcon>
										)}
										<NavItemText>
											<div className={navItem.labelClassName}>{navItem.label}</div>
										</NavItemText>
									</NavItem>
								))}
						</Nav>
					</div>
				</Row>
			</Container>
		</header>
	)
}
