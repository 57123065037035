import * as React from 'react'

import styles from './NavItemText.module.scss'

export type NavItemTextProps = {
	children?: React.ReactNode
}

export const NavItemText: React.FC<NavItemTextProps> = (props) => {
	return <div className={styles.wrap}>{props.children}</div>
}
