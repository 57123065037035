import { DefaultValues } from 'helpers/defaultsUtils'

export const moneyMovement = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		iconUrl: model.string(['iconUrl']),
		date: model.string(['date']),
		amount: model.null<number>(['amount']),
		title: model.string(['title']),
		direction: model.const(data.direction, {
			INCOME: 'INCOME',
			OUTCOME: 'OUTCOME',
		} as const),
	}
}
