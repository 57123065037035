import { DefaultValues } from 'helpers/defaultsUtils'

export const step = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		title: model.string(['title']),
		content: model.string(['content']),
		imageUrl: model.string(['imageUrl']),
	}
}
