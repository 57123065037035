import { onboardingActions } from 'actions/onboarding'
import api from 'api'
import { ReferallProgramDto } from 'converters/referallProgram'
import { profileReducerActions } from 'reducers/profile'
import { TAction } from 'types/redux'
import { FirstParameter } from 'types/utils'

import { notice } from 'components/common/Notice'

export const profileActions = {
	get: (): TAction<Promise<void>> => async (dispatch) => {
		try {
			dispatch(profileReducerActions.setLoading(true))

			const { data: profileData } = await api.profile.get()
			const { data: identificationData } = await api.profile.identification()
			const { data: selfieIdentificationData } = await api.profile.selfieIdentification()
			const { data: bonusAccount } = await api.profile.bonusAccount()
			const { data: referallPrograms } = await api.profile.searchReferallPrograms()

			await dispatch(onboardingActions.get())

			dispatch(profileReducerActions.setData(profileData))
			dispatch(profileReducerActions.setBonusAccount(bonusAccount))
			dispatch(profileReducerActions.setReferallProgramBrief(referallPrograms.content))
			dispatch(profileReducerActions.setSelfieIdentification(selfieIdentificationData))
			dispatch(profileReducerActions.setIdentification(identificationData))
		} catch (error) {
			// keycloak.logout()
		} finally {
			dispatch(profileReducerActions.setLoading(false))
		}
	},

	getReferallProgram:
		(
			payload: FirstParameter<typeof api.profile.getReferallProgram>
		): TAction<Promise<ReferallProgramDto.Main>> =>
		async (dispatch) => {
			try {
				const { data } = await api.profile.getReferallProgram(payload)

				dispatch(profileReducerActions.selectReferallProgramMain(data))

				return data
			} catch (error) {
				notice({ title: 'Ошибка', message: 'Не удалось загрузить сообщение' })
				throw error
			}
		},

	uploadSelfie:
		(payload: Parameters<typeof api.profile.uploadSelfie>[0]): TAction<Promise<void>> =>
		async () => {
			try {
				await api.profile.uploadSelfie(payload)
			} catch (error) {
				notice({ title: 'Ошибка', message: 'Не удалось отправить файлы' })

				throw error
			}
		},

	startIdentification:
		(payload: Parameters<typeof api.profile.startIdentification>[0]): TAction<Promise<void>> =>
		async (dispatch) => {
			try {
				await api.profile.startIdentification(payload)

				const { data: identificationData } = await api.profile.identification()

				dispatch(profileReducerActions.setIdentification(identificationData))
			} catch (error) {
				notice({ title: 'Ошибка', message: 'Не удалось отправить файлы' })

				throw error
			}
		},

	withdraw:
		(payload: FirstParameter<typeof api.profile.withdraw>): TAction<Promise<void>> =>
		async () => {
			try {
				await api.profile.withdraw(payload)
			} catch (error) {
				notice({ title: 'Ошибка', message: 'Не удалось вывести средства' })

				throw error
			}
		},

	update:
		(payload: FirstParameter<typeof api.profile.update>): TAction<Promise<void>> =>
		async (dispatch) => {
			try {
				await api.profile.update(payload)
				const { data: updatedProfile } = await api.profile.get()

				dispatch(profileReducerActions.setData(updatedProfile))
			} catch (error) {
				notice({ title: 'Ошибка', message: 'Не удалось обновить профиль' })

				throw error
			}
		},
}
