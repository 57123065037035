import always from 'ramda/src/always'
import anyPass from 'ramda/src/anyPass'
import both from 'ramda/src/both'
import compose from 'ramda/src/compose'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import identity from 'ramda/src/identity'
import ifElse from 'ramda/src/ifElse'
import is from 'ramda/src/is'
import isEmpty from 'ramda/src/isEmpty'
import not from 'ramda/src/not'
import pathOr from 'ramda/src/pathOr'
import trim from 'ramda/src/trim'
import { Path } from 'ramda/tools'

export class DefaultValues<T = Record<string, any>> {
	private data: T

	constructor(data: T) {
		this.data = data
	}

	null = <T extends number | boolean | Record<string, any> | any[]>(path: Path) =>
		pathOr<T | null>(null, path, this.data)

	string = <T extends string>(path: Path) => pathOr<T | ''>('', path, this.data)

	array = <T extends any[]>(path: Path) => pathOr<T>([] as any, path, this.data)

	const = <T1 extends string, T2 extends Record<string, string>>(
		value: T1,
		map: T2
	): T2[keyof T2] => defaultToStr(map[value]) as any
}

export const defaultToStr = defaultTo('') as <T>(v?: T | null) => NonNullable<T> | string
export const defaultToNull = defaultTo(null)
export const defaultToArr = defaultTo([])
export const defaultToApi = ifElse(
	anyPass([
		Number.isInteger,
		both(is(String), compose(not, isEmpty, trim)),
		is(Boolean),
		is(Object),
	]),
	ifElse(is(String), trim, identity),
	always(null)
)
export const numberOrNull = ifElse(equals(''), always(null), Number)
