import { DefaultValues } from 'helpers/defaultsUtils'

import { accountStats } from './accountStats';

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		accountStats: data?.accountStats ? accountStats(data.accountStats) : null,
		balance: model.null<number>(['balance']),
	}
}
