import { DefaultValues } from 'helpers/defaultsUtils'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		header: model.string(['header']),
		generalInstruction: model.string(['generalInstruction']),
		qrCodeInstruction: model.string(['qrCodeInstruction']),
		payeeAccountNumber: model.string(['payeeAccountNumber']),
		payeeName: model.string(['payeeName']),
		taxId: model.string(['taxId']),
		kpp: model.string(['kpp']),
		bankName: model.string(['bankName']),
		bic: model.string(['bic']),
		correspondentAccount: model.string(['correspondentAccount']),
		paymentPurpose: model.string(['paymentPurpose']),
		amount: model.null<number>(['amount']),
		qrData: model.string(['qrData']),
		sbpBankInstruction: data?.sbpBankInstruction
			? {
					bank: {
						name: model.string(['sbpBankInstruction', 'name']),
					},
					phone: model.string(['sbpBankInstruction', 'phone']),
			  }
			: null,
	}
}
