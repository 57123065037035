import { fileUtils } from '@creditclubteam/helpers'
import { DefaultValues } from 'helpers/defaultsUtils'

export const document = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		title: model.string(['title']),
		shortTitle: fileUtils.removeFileExtension(model.string(['title'])),
		url: model.string(['url']),
	}
}
