import { DefaultValues } from 'helpers/defaultsUtils'

export const additionalAccrual = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		annualRate: model.null<number>(['annualRate']),
		title: {
			id: model.null<number>(['title', 'id']),
			title: model.string(['title', 'title']),
		},
	}
}
