import * as React from 'react'
import { toast, ToastOptions } from 'react-toastify'
import clsx from 'clsx'

import { Text } from '../Text'

import styles from './Notice.module.scss'

export type NoticeProps = {
	title?: React.ReactNode
	message?: React.ReactNode
	titleClassName?: string
	messageClassName?: string
	type?: 'error' | 'info' | 'success'
	props?: ToastOptions
}

export const notice = ({
	title,
	message,
	titleClassName,
	messageClassName,
	type = 'error',
	props,
}: NoticeProps) => {
	const themeClassnames = {
		[styles['--error']]: type === 'error',
		[styles['--info']]: type === 'info',
		[styles['--success']]: type === 'success',
	}

	return toast(
		<div className={clsx(styles.content, themeClassnames)}>
			{title && (
				<Text tag='div' className={clsx(styles.title, themeClassnames, titleClassName)}>
					{title}
				</Text>
			)}
			{message && (
				<Text tag='div' className={messageClassName} theme='secondary' type='small'>
					{message}
				</Text>
			)}
		</div>,
		{
			...props,
			className: clsx(themeClassnames),
			type: 'default',
		}
	)
}
