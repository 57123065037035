import axios, { CancelToken } from 'axios'
import { document as documentConverter, DocumentDto } from 'converters/document'
import { Options } from 'types/utils'

const baseUrl = '/v3/signing'

export namespace DocumentsApi {
	export type Sign = void
	export type Confirm = { result: 'CONFIRMED' | 'DECLINED' }
	export type Search = DocumentDto.Main
}

export const document = {
	/**
	 * Операции с подписанием документов
	 * @description Инициализация процесса подписания
	 */
	sign: ({ id }: { id: string }) => axios.post<DocumentsApi.Sign>(`${baseUrl}/${id}/sign`),

	/**
	 * Операции с подписанием документов
	 * @description Подтверждение процесса подписания
	 */
	confirm: ({ id, code }: { id: string; code: string }) =>
		axios.post<DocumentsApi.Confirm>(`${baseUrl}/${id}/confirm`, { code }),

	search: (
		{
			filter,
			size = 100,
			page = 0,
		}: Options<{
			state?: Array<'ERROR' | 'SIGNED' | 'NEW' | 'ON_SIGNATURE'>
			signatureType?: 'SIMPLE' | 'QUALIFIED'
		}>,
		cancelToken?: CancelToken
	) => {
		const params = new URLSearchParams()

		filter?.state?.forEach((v) => params.append('state', v))
		filter?.signatureType && params.append('signatureType', filter.signatureType)
		params.append('size', size.toString())
		params.append('page', page.toString())

		return axios
			.get<DocumentDto.Main>(`${baseUrl}/document-packages?${params.toString()}`, { cancelToken })
			.then((response) =>
				Object.assign({}, response, { data: documentConverter.main(response.data) })
			)
	},

	upload: ({
		id,
		type,
		files,
		category,
		onUploadProgress,
	}: {
		id: string
		type: 'LEAD' | 'APPLICATION' | 'LOAN' | 'PERSON' | 'ORGANIZATION' | 'ENTREPRENEUR'
		category?: string
		files: FormData
		onUploadProgress: (...args: any) => any
	}) =>
		axios.post('/v2/client/documents/upload', files, {
			params: { entityType: type, entityId: id, category },
			onUploadProgress,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
}
