import { DefaultValues } from 'helpers/defaultsUtils'

export const list = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		balance: model.null<number>(['balance']),
		name: model.string(['name']),
		type: {
			id: model.string(['type', 'id']),
			title: model.string(['type', 'title']),
		},
	}
}
