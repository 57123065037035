import axios, { CancelToken } from 'axios'
import { bankAccount as bankAccountConverter, BankAccountDto } from 'converters/bankAccount'

export namespace BankAccountApi {
	export type Search = BankAccountDto.List[]
	export type Get = BankAccountDto.Single
}

export const bankAccount = {
	search: (cancelToken?: CancelToken) =>
		axios
			.get<BankAccountApi.Search>('/v3/products/bank-accounts', { cancelToken })
			.then((response) =>
				Object.assign({}, response, { data: response.data.map(bankAccountConverter.list) })
			),

	get: ({ id }: { id: string }, cancelToken?: CancelToken) =>
		axios
			.get<BankAccountApi.Get>(`/v3/products/bank-accounts/${id}`, {
				cancelToken,
				$$requestName: 'bankAccount.get',
			})
			.then((response) =>
				Object.assign({}, response, {
					data: bankAccountConverter.single(response.data),
				})
			),

	getRequisites: ({ id }: { id: string }) =>
		axios.get(`/v3/products/bank-accounts/${id}/requisites`),
}
