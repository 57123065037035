import { DefaultValues } from 'helpers/defaultsUtils'

export namespace InstructionDto {
	export type Steps = Text | Payment | Link | FillRequisites

	export interface Text {
		type: 'TEXT'
		id: string
		text: string
	}

	export interface Payment {
		type: 'PAYMENT'
		id: string
		text: string
		requisitesId: string
	}

	export interface Link {
		type: 'LINK'
		id: string
		text: string
		url: string
	}

	export interface FillRequisites {
		type: 'FILL_REQUISITES'
		id: string
		text: string
		requisitesHeader: string
		requisitesTitle: string
	}
}

const getSteps = (data: Record<string, any>): InstructionDto.Steps | null => {
	const model = new DefaultValues(data)

	switch (data.type) {
		case 'TEXT':
			return {
				type: data.type as 'TEXT',
				id: model.string(['id']),
				text: model.string(['text']),
			}
		case 'PAYMENT':
			return {
				type: data.type as 'PAYMENT',
				id: model.string(['id']),
				text: model.string(['text']),
				requisitesId: model.string(['requisitesId']),
			}
		case 'LINK':
			return {
				type: data.type as 'LINK',
				id: model.string(['id']),
				text: model.string(['text']),
				url: model.string(['url']),
			}
		case 'FILL_REQUISITES':
			return {
				type: 'FILL_REQUISITES',
				id: model.string(['id']),
				text: model.string(['text']),
				requisitesHeader: model.string(['requisitesHeader']),
				requisitesTitle: model.string(['requisitesTitle']),
			}

		default:
			return null
	}
}

export const instruction = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		steps: model.array(['steps']).map(getSteps).filter(Boolean) as InstructionDto.Steps[],
	}
}

