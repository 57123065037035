import { PropsWithChildren, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import clsx from 'clsx'
import { useMedia } from 'hooks'
import { TUtils } from 'types'

import { Footer } from '../Footer'

import { Background } from './Background'
import { Navigation } from './Navigation'

import styles from '../Layouts.module.scss'

export type MainLayoutProps = PropsWithChildren<{
	title: string
	props?: {
		specialMobile?: boolean
	}
	backgroundTheme?: React.ComponentProps<typeof Background>['theme']
}>

export const MainLayout = ({ title, props, backgroundTheme, children }: MainLayoutProps) => {
	const media = useMedia()
	const wrap = useRef<TUtils.Maybe<HTMLDivElement>>(null)

	const defaultMode = media === 'DESKTOP' || !props?.specialMobile

	const handlerInnerHeight = () => {
		if (wrap.current) {
			wrap.current.style.minHeight = `${window.innerHeight}px`
		}
	}

	useEffect(() => {
		handlerInnerHeight()

		window.addEventListener('resize', handlerInnerHeight)

		return () => {
			window.removeEventListener('resize', handlerInnerHeight)
		}
	}, [])

	return (
		<>
			{title && (
				<Helmet title={title}>
					<meta property='og:title' content={title} />
				</Helmet>
			)}
			<div ref={wrap} className={clsx(styles.wrap)}>
				{defaultMode && <Navigation />}
				<main className={clsx(styles.main, styles.fadeIn, defaultMode && styles.withNav)}>
					{backgroundTheme && <Background theme={backgroundTheme} />}
					{children}
				</main>
				{defaultMode && <Footer className={clsx(styles.footer, styles.fadeIn)} />}
			</div>
		</>
	)
}
