import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RequisitesDto } from 'converters/requisites'
import { EntityStatus } from 'types/redux'
import { Maybe } from 'types/utils'

import { commonReducers } from './commonReducers'

interface State {
	data: Maybe<RequisitesDto.Main>
	status: EntityStatus
}

const initialState: State = {
	data: null,
	status: 'pending',
}

const requisitesSlice = createSlice({
	name: 'requisites',
	initialState,

	reducers: {
		setData: (state, action: PayloadAction<RequisitesDto.Main>) => {
			state.data = action.payload
		},

		setStatus: commonReducers.setStatus,
		reset: commonReducers.reset(initialState),
	},
})

const { actions: requisitesReducerActions, reducer } = requisitesSlice

export { requisitesReducerActions, reducer as requisitesReducer }
