import axios from 'axios'
import { Onboarding, onboarding as onboardingConverter } from 'converters/onboarding'

const BASE_PATH = '/v3/app'

export const app = {
	onboardings: () =>
		axios
			.get<Onboarding.Main>(`${BASE_PATH}/onboardings/new-user`)
			.then((response) =>
				Object.assign({}, response, { data: onboardingConverter.main(response.data) })
			),

	completeOnboarding: ({ id }: { id: string }) => axios.post(`/v3/app/onboardings/${id}/complete`),
}
