import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SavingDto } from 'converters/saving'
import { TRedux } from 'types'
import { EntityStatus } from 'types/redux'

import { commonReducers } from '../commonReducers'

const savingListAdapter = createEntityAdapter<SavingDto.List>()

export const savingListSelector = savingListAdapter.getSelectors<TRedux.RootState>(
	(state) => state.saving.list
)

const initialState = savingListAdapter.getInitialState({
	status: 'pending' as EntityStatus,
	isInitialized: false,
})

const listSlice = createSlice({
	name: 'saving/list',
	initialState,

	reducers: {
		setData: savingListAdapter.setAll,

		setInitialized: (state, action: PayloadAction<boolean>) => {
			state.isInitialized = action.payload
		},

		setStatus: commonReducers.setStatus,
		reset: commonReducers.reset(initialState),
	},
})

const { actions: savingListReducerActions, reducer } = listSlice

export { savingListReducerActions, reducer as listReducer }
