import * as React from 'react'
import InputMask, { Props as InputMaskProps } from 'react-input-mask'
import clsx from 'clsx'
import EditIcon from 'images/icons/edit.svg'
import { TMedia } from 'types'

import { Image } from 'components/common'

import { Text } from '../Text'

import styles from './Input.module.scss'

const icons = {
	edit: EditIcon,
}

type ReactInput = React.DetailedHTMLProps<
	React.InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
>

export type InputProps = {
	label: string
	wrapperClassName?: string
	icon?: {
		onClick: () => void
		type: 'edit'
	}
	mask?: string
	inputClassName?: string
	error?: boolean
	errorMessage?: React.ReactNode
	indent?: TMedia.Indents
	minWidth?: string
} & ReactInput & { ref?: React.ForwardedRef<HTMLInputElement> }

const Input = React.forwardRef((props: InputProps, ref) => {
	const {
		minWidth,
		label,
		inputClassName,
		error,
		errorMessage,
		indent,
		mask = '',
		wrapperClassName,
		icon,
		...attributes
	} = props

	const [{ initialValue, isDirty, isFocused }, setState] = React.useState({
		isFocused: !!attributes.autoFocus,
		initialValue: attributes.value,
		isDirty: false,
	})

	React.useEffect(() => {
		if (attributes.value !== initialValue && !isDirty) {
			setState((prev) => ({ ...prev, isDirty: true }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [attributes.value])

	const eventHandler = (type: 'onChange' | 'onFocus' | 'onBlur', event: any) => {
		if (attributes.disabled || attributes.readOnly) return

		switch (type) {
			case 'onChange':
				{
					attributes.onChange && attributes.onChange(event)
				}
				break
			case 'onFocus':
				{
					setState((prev) => ({ ...prev, isFocused: true }))
					attributes.onFocus && attributes.onFocus(event)
				}
				break
			case 'onBlur': {
				setState((prev) => ({ ...prev, isFocused: false }))
				attributes.onBlur && attributes.onBlur(event)
			}
		}
	}

	const handleChange: InputProps['onChange'] = (event) => eventHandler('onChange', event)
	const handleActive: InputProps['onFocus'] = (event) => eventHandler('onFocus', event)
	const handleBlur: InputProps['onBlur'] = (event) => eventHandler('onBlur', event)

	const indentClassname = indent ? styles[indent] : undefined
	const className = clsx(styles.input, inputClassName, { [styles.iconPadding]: icon })

	const inputProps = mask
		? ({
				...attributes,
				ref,
				maskChar: null,
				mask,
				onBlur: handleBlur,
				onFocus: handleActive,
				onChange: handleChange,
				className,
		  } as InputMaskProps)
		: ({
				...attributes,
				ref,
				onBlur: handleBlur,
				onFocus: handleActive,
				onChange: handleChange,
				className,
		  } as React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>)

	const isActive = Boolean(
		isFocused ? true : isDirty ? attributes.value : attributes.value || attributes.defaultValue
	)

	const Icon = icon && icons[icon?.type]

	return (
		<div className={clsx(wrapperClassName, indentClassname)} style={{ minWidth }}>
			<div
				className={clsx(
					styles.wrap,
					{
						[styles.active]: isActive,
						[styles.focus]: isFocused,
						[styles.error]: error,
						[styles.disabled]: attributes.disabled,
					},
					attributes.className
				)}
			>
				<Text tag='label' className={styles.label} type={isActive ? 'small' : 'body'}>
					{label}
				</Text>
				{mask ? (
					<InputMask {...(inputProps as React.ComponentProps<typeof InputMask>)} />
				) : (
					<input {...inputProps} />
				)}
				{Icon && (
					<Text tag='div' className={styles.iconWrap}>
						<Image src={EditIcon} onClick={icon!.onClick} className={styles.icon} />
					</Text>
				)}
			</div>
			{error && errorMessage && (
				<Text tag='div' type='small' className={styles.errorMessage}>
					{errorMessage}
				</Text>
			)}
		</div>
	)
})

Input.defaultProps = {
	type: 'text',
	autoFocus: false,
	spellCheck: false,
}

export { Input }
