import * as React from 'react'
import clsx from 'clsx'

import styles from './Row.module.scss'

export type RowProps = {
	children?: React.ReactNode
	className?: string
}

const Row = (props: RowProps) => {
	return (
		<div
			className={clsx(styles.wrap, {
				[props.className!]: !!props.className,
			})}
		>
			{props.children}
		</div>
	)
}

export { Row }
