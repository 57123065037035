import axios from 'axios'
import { BonusAccountDto } from 'converters/bonusAccount'
import { bonusAccount as bonusAccountConverter } from 'converters/bonusAccount'
import {
	identification as identificationConverter,
	IdentificationDto,
} from 'converters/identification'
import { Profile, profile as profileConverter } from 'converters/profile'
import { referallProgram as referallProgramConverter } from 'converters/referallProgram'
import { ReferallProgramDto } from 'converters/referallProgram'
import { assocPath } from 'ramda'
import { Maybe, Pageable } from 'types/utils'

export namespace ProfileApi {
	export type Get = Profile.Main
	export type Identification = IdentificationDto.Main
	export type SelfieIdentification = Profile.SelfieIdentification
	export type BonusAccount = BonusAccountDto.Main
	export type Withdraw = void
	export type SearchReferallPrograms = Pageable<ReferallProgramDto.Brief>
	export type GetReferallProgram = Pageable<ReferallProgramDto.Main>
}

export const profile = {
	get: () =>
		axios
			.get<ProfileApi.Get>('/v3/profile/account')
			.then((response) =>
				Object.assign({}, response, { data: profileConverter.main(response.data) })
			),

	bonusAccount: () =>
		axios
			.get<ProfileApi.BonusAccount>('/v3/profile/bonus-account')
			.then((response) =>
				Object.assign({}, response, { data: bonusAccountConverter.main(response.data) })
			),

	searchReferallPrograms: () =>
		axios
			.get<ProfileApi.SearchReferallPrograms>('/v3/profile/referral-programs', {
				params: { size: 100, page: 0 },
			})
			.then((response) =>
				assocPath(
					['data', 'content'],
					response.data.content.map(referallProgramConverter.brief),
					response
				)
			),

	getReferallProgram: ({ id }: { id: string }) =>
		axios
			.get<ProfileApi.GetReferallProgram>(`/v3/profile/referral-programs/${id}`)
			.then((response) =>
				Object.assign({}, response, { data: referallProgramConverter.main(response.data) })
			),

	identification: () =>
		axios
			.get<ProfileApi.Identification>('/v3/profile/identification')
			.then((response) =>
				Object.assign({}, response, { data: identificationConverter.main(response.data) })
			),

	selfieIdentification: () =>
		axios
			.get<ProfileApi.SelfieIdentification>('/v3/profile/selfie-identification')
			.then((response) =>
				Object.assign({}, response, { data: profileConverter.selfieIdentification(response.data) })
			),

	uploadSelfie: (payload: FormData) =>
		axios.post('/v3/profile/selfie-identification', payload, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),

	withdraw: (payload: { amount: number }) =>
		axios.post<ProfileApi.Withdraw>('/v3/profile/bonus-account/withdraw', payload),

	startIdentification: (payload: FormData) =>
		axios.post('/v3/profile/identification', payload, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),

	update: (payload: {
		bankRequisites?: { bic: Maybe<string>; accountNumber: Maybe<string> }
		email?: Maybe<string>
	}) =>
		axios.patch(`/v3/profile/account`, payload, {
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),
}
