import { DefaultValues } from 'helpers/defaultsUtils'

export const moneyMovement = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		iconUrl: model.string(['iconUrl']),
		date: model.string(['date']),
		amount: model.null<number>(['amount']),
		title: model.string(['title']),
		direction: model.const(data.direction, {
			INCOME: 'INCOME',
			OUTCOME: 'OUTCOME',
		} as const),
		operationDetails: model.array(['operationDetails']).map((_, i) => ({
			amount: model.null<number>(['operationDetails', i, 'amount']),
			type: {
				id: model.string(['operationDetails', i, 'type', 'id']),
				title: model.string(['operationDetails', i, 'type', 'title']),
			},
		})),
	}
}
