import { PayloadAction } from '@reduxjs/toolkit'

export const reset =
	<T extends Record<string, any>>(initialState: T) =>
	(state: T, action: PayloadAction<keyof T | Array<keyof T> | undefined>) => {
		if (typeof action.payload === 'string') {
			state[action.payload as keyof typeof state] = initialState[action.payload]

			return state
		}

		if (Array.isArray(action.payload)) {
			action.payload.forEach((key) => {
				state[key] = initialState[key]
			})

			return state
		}

		return initialState
	}
