import * as React from 'react'
import type { TabPanelProps as ReactTabPanelProps } from 'react-tabs'
import { TabPanel as ReactTabPanel } from 'react-tabs'
import clsx from 'clsx'

import styles from './TabPanel.module.scss'

export type TabPanelProps = ReactTabPanelProps & { className?: string }

const TabPanel: React.FC<TabPanelProps> & { tabsRole?: string } = ({
	children,
	className,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	ref,
	...restOfProps
}) => {
	return (
		<ReactTabPanel
			{...restOfProps}
			className={clsx(styles.wrap, {
				[className!]: !!className,
			})}
			selectedClassName={clsx(styles.selected, {
				[restOfProps.selectedClassName!]: !!restOfProps.selectedClassName,
			})}
		>
			{children}
		</ReactTabPanel>
	)
}

TabPanel.tabsRole = 'TabPanel'

export { TabPanel }
