import { createSelector } from '@reduxjs/toolkit'
import { documentArchiveSelector } from 'reducers/document/archive'
import { documentSigningSelector } from 'reducers/document/signing'
import { RootState } from 'types/redux'

type Keys = 'archive' | 'signing'

export const documentSelectors = {
	archive: documentArchiveSelector,
	signing: documentSigningSelector,
} satisfies Record<Keys, any>

export const selectDocumentsStatus = createSelector(
	[(state: RootState) => state.document, (_: any, key: Keys) => key],
	(reducers, key) => reducers[key].status
)

export const selectSimplePackageOnSignature = createSelector(
	documentSigningSelector.selectAll,
	(items) =>
		items.find(({ state, signatureType }) => signatureType === 'SIMPLE' && state === 'ON_SIGNATURE')
)

export const selectQualifiedPackageOnSignature = createSelector(
	documentSigningSelector.selectAll,
	(items) =>
		items.find(
			({ state, signatureType }) => signatureType === 'QUALIFIED' && state === 'ON_SIGNATURE'
		)
)

export const selectAnyPackageOnSignature = createSelector(
	documentSigningSelector.selectAll,
	(items) => items.find(({ state }) => state === 'ON_SIGNATURE')
)
