import { DefaultValues } from 'helpers/defaultsUtils'

export const linkedEntity = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		taxId: model.string(['taxId']),
		title: model.string(['title']),
	}
}
