import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoanDto } from 'converters/loan'
import { TUtils } from 'types'

import { commonReducers } from '../commonReducers'

interface State {
	data: Partial<Record<string, LoanDto.Single>>
	loadingId: TUtils.Maybe<string>
}

const initialState: State = {
	data: {},
	loadingId: null,
}

const singleSlice = createSlice({
	name: 'loan/single',
	initialState,

	reducers: {
		setData: (state, action: PayloadAction<{ key: string; value: LoanDto.Single }>) => {
			state.data[action.payload.key] = action.payload.value
		},

		deleteDocument: (state, action: PayloadAction<{ loanId: string; documentId: string }>) => {
			if (state.data[action.payload.loanId]) {
				state.data[action.payload.loanId]!.documents = state.data[
					action.payload.loanId
				]!.documents.filter(({ id }) => id !== action.payload.documentId)
			}
		},

		setLoadingId: (state, action: PayloadAction<State['loadingId']>) => {
			state.loadingId = action.payload
		},

		reset: commonReducers.reset(initialState),
	},
})

const { actions: loanSingleReducerActions, reducer } = singleSlice

export { loanSingleReducerActions, reducer as singleReducer }
