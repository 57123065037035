import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DocumentDto } from 'converters/document'
import { commonReducers } from 'reducers/commonReducers'
import { EntityStatus, RootState } from 'types/redux'

const adapter = createEntityAdapter<DocumentDto.Content>()

export const documentSigningSelector = adapter.getSelectors<RootState>(
	(state) => state.document.signing
)

const initialState = adapter.getInitialState({
	status: 'pending' as EntityStatus,
})

const signingSlice = createSlice({
	name: 'document/signing',
	initialState,
	reducers: {
		setPackageState: (
			state,
			{ payload }: PayloadAction<{ id: string; value: DocumentDto.Content['state'] }>
		) => {
			if (state.entities[payload.id]) state.entities[payload.id]!.state = payload.value
		},

		setData: adapter.upsertMany,
		removePackage: adapter.removeOne,
		setStatus: commonReducers.setStatus,
		reset: commonReducers.reset(initialState),
	},
})

const { actions, reducer } = signingSlice

export { actions as signingReducerActions, reducer as signingReducer }
